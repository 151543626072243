import { createSlice, Draft, PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import { ClassConstructor } from 'class-transformer';
import { BaseModel, PatchLogEventPayload } from 'interfaces';
import * as R from 'ramda';
import { Dispatch } from 'redux';
import { ObjectPropertiesOfType, SubscriptiveResourceList } from 'slices/subscriptive';
import { Socket } from 'socket.io-client';
import { emitAsyncOrdered } from 'utils/socket';
import { setError } from '../errors';

export interface RelativeSubscriptiveResourceList<T extends BaseModel> {
  [key: string]: SubscriptiveResourceList<T>;
}

export type RootState = Record<string, Record<string, SubscriptiveResourceList<BaseModel>>>;
export type RelativeRootState = RootState;
export const createRelativeSubscriptiveSlice = ({
  name,
  parentName,
  sliceName,
  payloadType,
  idProp,
  parentSingleName,
  deletedFilterFn,
  reducers,
}: {
  deletedFilterFn: (resource: BaseModel) => boolean;
  name: string;
  sliceName?: string;
  parentName: string;
  payloadType: ClassConstructor<BaseModel>;
  parentSingleName: string;
  idProp: Extract<ObjectPropertiesOfType<BaseModel, string>, string>;
  reducers: ValidateSliceCaseReducers<
    RelativeSubscriptiveResourceList<BaseModel>,
    SliceCaseReducers<RelativeSubscriptiveResourceList<BaseModel>>
  >;
}) => {
  const selectChildResourceList = (parentId: string | null, state: RootState) => {
    // Do not sort patchLogEvents as they are already correctly sorted on backend
    if (name === 'patchLogEvents' && parentName === 'manualPatches') {
      return parentId && Object.values(state[sliceName || name][parentId]?.resourceDictionary || []);
    }

    console.time('SORT');
    parentId &&
      R.sortWith([R.descend(R.prop('createdAt'))], R.values(state[sliceName || name][parentId]?.resourceDictionary));
    console.timeEnd('SORT');

    return parentId
      ? R.sortWith([R.descend(R.prop('createdAt'))], R.values(state[sliceName || name][parentId]?.resourceDictionary))
      : null;
  };

  const initialState: SubscriptiveResourceList<BaseModel> = {
    resourceDictionary: {},
    subscribed: false,
    lastUpdatedAt: null,
    loading: false,
  };

  const slice = createSlice({
    name: sliceName || name,
    initialState: {},
    reducers: {
      resetResource: (
        state: Draft<RelativeSubscriptiveResourceList<BaseModel>>,
        { payload: { parentId } }: PayloadAction<{ parentId: string }>
      ) => {
        state[parentId].resourceDictionary = {};
      },
      setLoadingResources: (
        state: Draft<RelativeSubscriptiveResourceList<BaseModel>>,
        {
          payload: { parentId, ids, state: loadState },
        }: PayloadAction<{ parentId: string; ids: string[]; state: boolean }>
      ) => {
        const dictionary = state[parentId].resourceDictionary;
        for (const id of ids) {
          const resource = dictionary[id];
          if (resource) {
            resource.loading = loadState;
          }
        }
      },
      onPartial: (
        state: Draft<RelativeSubscriptiveResourceList<BaseModel>>,
        { payload: { data: resources, parentId } }: PayloadAction<{ parentId: string; data: BaseModel[] }>
      ) => {
        console.log(`on ${parentSingleName}/${name} partial`, resources);
        if (!state[parentId]) {
          state[parentId] = initialState;
        }
        const deletedResourceIds: string[] = R.pipe<BaseModel[], BaseModel[], string[]>(
          R.filter(deletedFilterFn),
          R.map(R.prop<any, any>(idProp))
        )(resources);

        const updatedResources: BaseModel[] = R.filter(R.complement(deletedFilterFn), resources);

        const indexedResources = R.indexBy(R.prop(idProp), updatedResources);
        const initialResources = state[parentId].resourceDictionary;

        if (parentSingleName === 'manualPatch' && name === 'patchLogEvents') {
          for (const id in indexedResources) {
            if (initialResources[id] !== undefined) {
              if (
                (indexedResources[id] as PatchLogEventPayload).error === undefined &&
                (indexedResources[id] as PatchLogEventPayload).warning === undefined
              ) {
                (indexedResources[id] as PatchLogEventPayload).error = (
                  initialResources[id] as PatchLogEventPayload
                ).error;
                (indexedResources[id] as PatchLogEventPayload).warning = (
                  initialResources[id] as PatchLogEventPayload
                ).warning;
              }

              if ((indexedResources[id] as PatchLogEventPayload).duration === undefined) {
                (indexedResources[id] as PatchLogEventPayload).duration = (
                  initialResources[id] as PatchLogEventPayload
                ).duration;
              }
            }
          }
        }

        Object.assign(state[parentId].resourceDictionary, indexedResources);

        for (const resourceId of deletedResourceIds) {
          delete state[parentId].resourceDictionary[resourceId];
        }

        const lastUpdatedPatch = R.pipe<BaseModel[], BaseModel[], BaseModel>(
          R.sortBy(R.prop('updatedAt')),
          R.last
        )(Object.values(state[parentId].resourceDictionary));

        state[parentId].lastUpdatedAt = lastUpdatedPatch?.updatedAt;
        console.timeEnd('onPartial');
      },
      onPublish: (
        state: Draft<RelativeSubscriptiveResourceList<BaseModel>>,
        { payload: { data: resources, parentId } }: PayloadAction<{ parentId: string; data: BaseModel[] }>
      ) => {
        console.log(`on ${parentSingleName}/${name} publish`, resources);
        if (!state[parentId]) {
          state[parentId] = initialState;
        }
        const deletedResourceIds: string[] = R.pipe<BaseModel[], BaseModel[], string[]>(
          R.filter(deletedFilterFn),
          R.map(R.prop<any, any>(idProp))
        )(resources);

        const updatedResources: BaseModel[] = R.filter(R.complement(deletedFilterFn), resources);

        // TODO: partial update implemented only for patchLogEvents
        if (parentSingleName === 'manualPatch' && name === 'patchLogEvents') {
          state[parentId].resourceDictionary = R.indexBy(R.prop(idProp), updatedResources);
        } else {
          Object.assign(state[parentId].resourceDictionary, R.indexBy(R.prop(idProp), updatedResources));
        }

        for (const resourceId of deletedResourceIds) {
          delete state[parentId].resourceDictionary[resourceId];
        }

        const lastUpdatedPatch = R.pipe<BaseModel[], BaseModel[], BaseModel>(
          R.sortBy(R.prop('updatedAt')),
          R.last
        )(Object.values(state[parentId].resourceDictionary));

        state[parentId].lastUpdatedAt = lastUpdatedPatch?.updatedAt;
        console.timeEnd('onPublish');
      },
      setLoading: (
        state: Draft<RelativeSubscriptiveResourceList<BaseModel>>,
        { payload: { loading, parentId } }: PayloadAction<{ loading: boolean; parentId: string }>
      ) => {
        if (state[parentId]) state[parentId].loading = loading;
      },
      initChildState: (
        state: Draft<RelativeSubscriptiveResourceList<BaseModel>>,
        { payload: parentId }: PayloadAction<string>
      ) => {
        if (!state[parentId]) {
          state[parentId] = initialState;
        }
      },
      setSubscribed: (
        state: Draft<RelativeSubscriptiveResourceList<BaseModel>>,
        { payload: { parentId, subscribed } }: PayloadAction<{ subscribed: boolean; parentId: string }>
      ) => {
        if (state[parentId]) {
          state[parentId].subscribed = subscribed;
        }
      },
      ...reducers,
    },
  });

  const { onPartial, onPublish, setLoading, setSubscribed, initChildState, setLoadingResources, resetResource } =
    slice.actions;

  const select = (parentId: string | null, state: RootState) => (parentId ? state[sliceName || name][parentId] : null);

  const unsubscribe =
    (parentId: string, data?: Record<string, unknown>) =>
    async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket) => {
      const socket = getSocket();
      dispatch(setLoading({ parentId, loading: true }));
      socket.off(`${parentName}/${parentId}/${name}:publish`);
      socket.off(`${parentName}/${parentId}/${name}:partial`);
      if (getSocket().connected) {
        await emitAsyncOrdered(socket, `${parentSingleName}/${name}`, `${parentSingleName}/${name}:unsubscribe`, {
          [`${parentSingleName}Id`]: parentId,
          ...data,
        });
      }

      dispatch(setLoading({ loading: false, parentId }));
      dispatch(setSubscribed({ subscribed: false, parentId }));
    };

  const subscribe =
    (parentId: string, data?: Record<string, unknown>) =>
    async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket) => {
      const socket = getSocket();
      await dispatch(initChildState(parentId));
      const childState = select(parentId, getState());
      const { lastUpdatedAt } = childState || {};
      dispatch(setLoading({ loading: true, parentId }));
      const context: {
        acc: BaseModel[];
        timeout: NodeJS.Timeout | null;
        count: number;
      } = {
        acc: [],
        timeout: null,
        count: 0,
      };
      socket.on(`${parentName}/${parentId}/${name}:partial`, (data: BaseModel[]) => {
        dispatch(
          onPartial({
            parentId,
            data,
          })
        );
      });
      socket.on(`${parentName}/${parentId}/${name}:publish`, (data: BaseModel[]) => {
        dispatch(
          onPublish({
            parentId,
            data,
          })
        );
      });
      const { status, msg } = await emitAsyncOrdered(
        socket,
        `${parentSingleName}/${name}`,
        `${parentSingleName}/${name}:subscribe`,
        {
          lastUpdatedAt,
          [`${parentSingleName}Id`]: parentId,
          ...data,
        },
        false
      );
      if (status === 'ok') {
        dispatch(setSubscribed({ subscribed: true, parentId }));
      } else {
        if (status === 'error') {
          dispatch(setError({ status: status, msg: msg }));
        }
        dispatch(setSubscribed({ subscribed: false, parentId }));
      }
      dispatch(setLoading({ loading: false, parentId }));
    };

  const reconnect = () => async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket) => {
    const socket = getSocket();
    const state = getState();
    const parentIds = R.keys<RootState>(state) as string[];
    await Promise.all(
      R.map(async (parentId: string) => {
        if (state[parentId]?.subscribed) {
          await dispatch(setSubscribed({ parentId, subscribed: false }));
          await dispatch(subscribe(parentId));
        }
      }, parentIds)
    );
  };
  //
  // const create = (data: TInCreate) => async (
  //   dispatch: Dispatch<any>,
  //   getState: () => RootState,
  //   getSocket: () => Socket
  // ): Promise<ResponsePayload<S>> => {
  //   const socket = getSocket();
  //   dispatch(setLoading(true));
  //   const response = await emitAsync<ResponsePayload<S>>(
  //     socket,
  //     `${name}:create`,
  //     data
  //   );
  //   dispatch(setLoading(false));
  //   return response;
  // };
  //
  // const update = (data: TInUpdate) => async (
  //   dispatch: Dispatch<any>,
  //   getState: () => RootState,
  //   getSocket: () => Socket
  // ): Promise<ResponsePayload<S>> => {
  //   const socket = getSocket();
  //   dispatch(setLoading(true));
  //   const response = await emitAsync<ResponsePayload<S>>(
  //     socket,
  //     `${name}:update`,
  //     data
  //   );
  //   dispatch(setLoading(false));
  //   return response;
  // };
  //
  // const remove = (data: { id: string }) => async (
  //   dispatch: Dispatch<any>,
  //   getState: () => RootState,
  //   getSocket: () => Socket
  // ): Promise<ResponsePayload<S>> => {
  //   const socket = getSocket();
  //   dispatch(setLoading(true));
  //   const response = await emitAsync<ResponsePayload<S>>(
  //     socket,
  //     `${name}:remove`,
  //     data
  //   );
  //   dispatch(setLoading(false));
  //   return response;
  // };

  const reducer = slice.reducer;
  return {
    resourceId: `${parentSingleName}/${name}`,
    select,
    reconnect,
    unsubscribe,
    reducer,
    onPartial,
    onPublish,
    setLoadingResources,
    resetResource,
    subscribe,
    // create,
    // update,
    // remove,
    selectChildResourceList,
    slice,
  };
};
