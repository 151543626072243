import { CopyOutlined } from '@ant-design/icons';
import { Button, notification, Tooltip } from 'antd';
import type { FC, PropsWithChildren } from 'react';

interface OwnProps {
  text?: string;
  tooltip?: string;
}

const CopyToClipboard: FC<PropsWithChildren<OwnProps>> = ({ children, text, tooltip }) => {
  const copyToClipboard = async () => {
    if (!text) {
      return;
    }

    try {
      await navigator.clipboard.writeText(text);
      notification.success({
        message: 'Copied!',
      });
    } catch (error) {
      notification.error({
        message: error instanceof Error ? error.message : 'Problem accessing clipboard',
      });
    }
  };

  const copyButton = <Button onClick={copyToClipboard} icon={<CopyOutlined />} type="text" size="small" />;

  return children ? (
    <>
      <span style={{ display: 'inline-block', paddingRight: '24px', position: 'relative', width: '100%' }}>
        {children}
        <span
          style={{
            display: 'block',
            position: 'absolute',
            top: 'calc(50% - 1.6px)',
            right: '0',
            transform: 'translateY(-50%)',
          }}
        >
          {tooltip ? <Tooltip title={tooltip}>{copyButton}</Tooltip> : copyButton}
        </span>
      </span>
    </>
  ) : null;
};

export default CopyToClipboard;
