import { FormOutlined, PlayCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Card, Col, Collapse, Layout, Menu, Row, Typography } from 'antd';
import type { FC } from 'react';
import { Link, Route, useLocation, useRouteMatch } from 'react-router-dom';

const Panel = Collapse.Panel;

const VideoCard = ({ title, poster, videoSrc, description }: { title: string, poster: string, videoSrc: string, description: string }) => (
  <Card
    title={title}
    cover={
      <video
        controls
        controlsList="nodownload"
        onContextMenu={(e) => e.preventDefault()}
        preload="none"
        poster={poster}
      >
        <source src={videoSrc} type="video/webm" />
        Your browser does not support this video format.
      </video>
    }
  >
    <p>{description}</p>
  </Card>
);

const HelpPage: FC = () => {
  const { path, url } = useRouteMatch();
  const location = useLocation();
  const currentSubRoute = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
  const videoData = [
    {
      title: "Selector and Log chart area",
      poster: "/media/0.%20Selectors%20and%20Log%20chart%20area.jpg",
      videoSrc: "/media/0.%20Selectors%20and%20Log%20chart%20area.webm",
      description: "Introduction to the controls for working with log events of a selected driver."
    },
    {
      title: "Status area",
      poster: "/media/1.%20Status%20area.jpg",
      videoSrc: "/media/1.%20Status%20area.webm",
      description: "Introduction to the log (status) area."
    },
    {
      title: "Functional Buttons. Part 1",
      poster: "/media/2.%20Functional%20Buttons.%20Part%201.%20Move%20on.jpg",
      videoSrc: "/media/2.%20Functional%20Buttons.%20Part%201.%20Move%20on.webm",
      description: "Introduction to the functions. Part 1."
    },
    {
      title: "Functional Buttons. Part 2",
      poster: "/media/3.%20Functional%20Buttons.%20Part%202.jpg",
      videoSrc: "/media/3.%20Functional%20Buttons.%20Part%202.webm",
      description: "Introduction to the functions. Part 2."
    },
    {
      title: "Functional Buttons. Part 3",
      poster: "/media/4.%20Functional%20Buttons.%20Part%203.jpg",
      videoSrc: "/media/4.%20Functional%20Buttons.%20Part%203.webm",
      description: "Introduction to the functions. Part 3."
    },
    {
      title: "Co-driver mode",
      poster: "/media/6.%20Co-driver%20mode%20(explanation%20video).jpg",
      videoSrc: "/media/6.%20Co-driver%20mode%20(explanation%20video).webm",
      description: "Learn about Co-driver mode."
    },
    {
      title: "Distance between two events - prevent teleports",
      poster: "/media/7.%20Distance%20between%20two%20points.jpg",
      videoSrc: "/media/7.%20Distance%20between%20two%20points.webm",
      description: "Learn regarding the warning from the automatic log check about a substantial discrepancy in distance values between two events. This warning is crucial for preventing teleportation issues."
    },
    {
      title: "Warning about downloaded statuses - yellow edit button",
      poster: "/media/8.%20Downloaded%20status%20(Yellow%20edit%20button).jpg",
      videoSrc: "/media/8.%20Downloaded%20status%20(Yellow%20edit%20button).webm",
      description: "Learn about warnings when editing downloaded statuses."
    },
    {
      title: "Adjust odometer value",
      poster: "/media/9.%20odometer.jpg",
      videoSrc: "/media/9.%20odometer.webm",
      description: "Learn about easily adjusting odometer value on multiple events."
    },
    {
      title: "ALERTS page",
      poster: "/media/5.%20ALERTS%20function.jpg",
      videoSrc: "/media/5.%20ALERTS%20function.webm",
      description: "Learn about adding a company or driver specific alert."
    }
  ];

  return (
    <Layout.Content style={{ padding: '50px', minHeight: 'calc(100vh - 64px)' }}>
      <Typography.Title level={3} style={{ textTransform: 'uppercase' }}>
        Help & Support
      </Typography.Title>
      <Layout style={{ background: 'white' }}>
        <Layout.Sider width={200} style={{ background: 'white' }}>
          <Menu
            mode="inline"
            theme="dark"
            style={{ height: '100%' }}
            defaultSelectedKeys={['tutorials']}
            selectedKeys={[currentSubRoute === path.slice(1) ? 'tutorials' : currentSubRoute]}
          >
            <Menu.Item key="tutorials" icon={<PlayCircleOutlined />}>
              <Link to={`${url}/tutorials`}>Videos & Tutorials</Link>
            </Menu.Item>
            <Menu.Item key="faq" icon={<QuestionCircleOutlined />}>
              <Link to={`${url}/faq`}>FAQ</Link>
            </Menu.Item>
            <Menu.Item key="feedback" icon={<FormOutlined />}>
              <Link to={`${url}/feedback`}>Feedback</Link>
            </Menu.Item>
          </Menu>
        </Layout.Sider>
        <Layout.Content style={{ padding: '12px 24px', minHeight: 280 }}>
          <Route
            exact
            path={[
              `${path}/`,
              `${path}/tutorials`,
            ]}
          >
            <Typography.Title level={4}>Videos & Tutorials</Typography.Title>
            <Row
              gutter={[
                16,
                16,
              ]}
            >
              {videoData.map((video, index) => (
                <Col key={index} span={8}>
                  <VideoCard {...video} />
                </Col>
              ))}
            </Row>
          </Route>
          <Route path={`${path}/faq`}>
            <Typography.Title level={4}>FAQ</Typography.Title>
            <Collapse defaultActiveKey={['1']}>
              <Panel header="How to add a new user?" key="1">
                <p>
                  You can add a new user from the Users tab. Note, your account must have administrator privileges to
                  access the Users tab.
                </p>
              </Panel>
              <Panel header="How to delete or disable a user?" key="2">
                <p>
                  You can delete or disable users from the Users tab. Note, your account must have administrator
                  privileges to access the Users tab.
                </p>
              </Panel>
            </Collapse>
          </Route>
          <Route path={`${path}/feedback`}>
            <Typography.Title level={4}>Feedback</Typography.Title>
            <iframe
              title="Feedback form"
              src="https://docs.google.com/forms/d/e/1FAIpQLSc3peuxYOh8dgxKCVNT5FHJ1qczJAn9fKneC0pfCJj6y-7CjQ/viewform?embedded=true"
              style={{ border: '0', width: '100%', minHeight: '680px' }}
            >
              Loading…
            </iframe>
          </Route>
        </Layout.Content>
      </Layout>
    </Layout.Content>
  );
};

export default HelpPage;
