import * as d3 from 'd3';
import { D3BrushEvent } from 'd3';
import moment from 'moment-timezone';
import * as R from 'ramda';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useD3 } from '../hooks/useD3';

export type DrivingEventType = 'DS_ON' | 'DS_OFF' | 'DS_SB' | 'DS_D' | 'DR_IND_YM' | 'DR_IND_PC';
export type ChartEvent = {
  id: string;
  timestamp: number;
  type: DrivingEventType;
  timezone: string;
  duration: number;
  color: string;
};

type DriverLogsChartProps = {
  events: ChartEvent[];
  onMount: (setHighlightedEventId: Dispatch<SetStateAction<string | null>> | null) => void;
  onEventHover: (event?: ChartEvent) => void;
};

const DriverLogsChart: React.FC<DriverLogsChartProps> = ({ events, onMount, onEventHover }) => {
  const [
    eventIdToHighlight,
    setEventIdToHighlight,
  ] = useState<string | null>(null);

  useEffect(() => {
    onMount(setEventIdToHighlight);

    return () => onMount(null);
  }, [onMount]);

  // Sizing
  const width = 1820;
  const height = 200;
  const margin = {
    top: 20,
    right: 65,
    bottom: 30,
    left: 40,
  };
  const focusHeight = 75;
  const innerWidth = useMemo(
    () => width - margin.left - margin.right,
    [
      margin.left,
      margin.right,
    ]
  );

  // Styling
  const color = '#00ff00';
  const strokeLinecap = 'round'; // stroke line cap of the line
  const strokeLinejoin = 'round'; // stroke line join of the line
  const strokeWidth = 1.5; // stroke width of line, in pixels
  const strokeOpacity = 1; // stroke opacity of line

  // Method of interpolation between points
  const curve = d3.curveStepAfter;

  // Formatters
  const yFormat = useCallback((type: DrivingEventType) => {
    switch (type) {
      case 'DS_ON':
      case 'DR_IND_YM':
        return 'ON';
      case 'DS_D':
        return 'D';
      case 'DS_SB':
        return 'SB';
      case 'DS_OFF':
      case 'DR_IND_PC':
        return 'OFF';
      default:
        return '';
    }
  }, []);
  const timezone = events[0]?.timezone;
  const xFormat = useCallback(
    (date: Date) => {
      const time = timezone ? moment(date).tz(timezone) : moment(date);

      if (time.minutes() === 0) {
        const hours = time.hours();
        switch (hours) {
          case 0:
            return 'M';
          case 12:
            return 'N';
          default:
            return (hours % 12).toString();
        }
      }

      return '';
    },
    [timezone]
  );

  // Compute values
  const X = useMemo(() => d3.map(events, (event) => event.timestamp), [events]);
  const xFocusTickValues = useMemo(() => {
    const start = moment(X[0]);
    const end = moment(X[X.length - 1]);
    return d3.timeHour.range(start.toDate(), end.toDate()).filter((date) => {
      const time = timezone ? moment(date).tz(timezone) : moment(date);
      return time.hours() % 4 === 0;
    });
  }, [
    X,
    timezone,
  ]);
  const Y = useCallback(
    (type: DrivingEventType): DrivingEventType =>
      type === 'DR_IND_YM' ? 'DS_ON' : type === 'DR_IND_PC' ? 'DS_OFF' : type,
    []
  );

  const xRange = useMemo(
    () => [
      margin.left,
      width - margin.right,
    ],
    [
      margin.left,
      margin.right,
    ]
  );
  const yRange = useMemo(
    () => [
      height - margin.bottom,
      margin.top,
    ],
    [
      margin.bottom,
      margin.top,
    ]
  );
  const xDomain = useMemo(() => d3.extent(X) as [number, number], [X]);
  const yDomainLeft = useMemo(
    () =>
      [
        'DS_ON',
        'DS_D',
        'DS_SB',
        'DS_OFF',
      ] as DrivingEventType[],
    []
  );
  const eventsTotal = useMemo(() => {
    const grouped = d3.rollup(
      events,
      (items) => d3.sum(items, (item) => item.duration || 0),
      (event) => Y(event.type)
    );

    for (const key of yDomainLeft) {
      if (!grouped.has(key)) {
        grouped.set(key, 0);
      }
    }

    return Array.from(grouped.entries()).sort(
      (
        [
          type1,
        ],
        [
          type2,
        ]
      ) => yDomainLeft.indexOf(type1) - yDomainLeft.indexOf(type2)
    );
  }, [
    Y,
    events,
    yDomainLeft,
  ]);
  const yDomainRight = useMemo(
    () => (selectedEvents: ChartEvent[]) => {
      return eventsTotal.map(
        ([
          key,
          totalDuration,
        ]) => {
          const selectedDuration = d3.sum(
            selectedEvents.filter((event) => Y(event.type) === key),
            (item) => item.duration || 0
          );
          const selectedSeconds = Math.floor(selectedDuration / 1000);
          const selectedMinutes = Math.floor(selectedSeconds / 60);
          const selectedHours = Math.floor(selectedMinutes / 60);
          const totalSeconds = Math.floor(totalDuration / 1000);
          const totalMinutes = Math.floor(totalSeconds / 60);
          const totalHours = Math.floor(totalMinutes / 60);

          const selected = `${selectedHours.toString().padStart(2, '0')}:${(selectedMinutes % 60)
            .toString()
            .padStart(2, '0')}:${(selectedSeconds % 60).toString().padStart(2, '0')}`;
          const total = `${totalHours.toString().padStart(2, '0')}:${(totalMinutes % 60)
            .toString()
            .padStart(2, '0')}:${(totalSeconds % 60).toString().padStart(2, '0')}`;

          return `${selected} / ${total}`;
        }
      );
    },
    [
      Y,
      eventsTotal,
    ]
  );

  // Construct scales and axes.
  const xScale = useMemo(
    () => d3.scaleTime(xDomain, xRange),
    [
      xDomain,
      xRange,
    ]
  );
  const yScaleLeft = useMemo(
    () => d3.scalePoint<DrivingEventType>(yDomainLeft, yRange).padding(0.5),
    [
      yDomainLeft,
      yRange,
    ]
  );
  const yScaleRight = useMemo(
    () => d3.scalePoint<string>(yDomainRight([]), yRange).padding(0.5),
    [
      yDomainRight,
      yRange,
    ]
  );

  const xAxis = (xScale: d3.ScaleTime<number, number>) =>
    d3.axisBottom<Date>(xScale).ticks(d3.timeMinute.every(15)).tickFormat(xFormat).tickSizeOuter(0);
  const yAxisLeft = (yScale: d3.ScalePoint<DrivingEventType>) =>
    d3.axisLeft<DrivingEventType>(yScale).tickFormat(yFormat).tickSizeOuter(0);
  const yAxisRight = (yScale: d3.ScalePoint<string>) => d3.axisRight<string>(yScale).tickSizeOuter(0);

  const line = (xScale: d3.ScaleTime<number, number>, yScale: d3.ScalePoint<DrivingEventType>) =>
    d3
      .line<ChartEvent>()
      .curve(curve)
      .x((d) => xScale(d.timestamp))
      .y((d) => yScale(Y(d.type))!);

  const xGrid = (g: d3.Selection<SVGGElement, ChartEvent, HTMLElement, any>) => {
    g.selectAll<SVGLineElement, Date>('.tick > line')
      .attr('stroke-opacity', (d: Date) => {
        switch (d.getMinutes()) {
          case 0:
            return 0.5;
          case 30:
            return 0.4;
          default:
            return 0.3;
        }
      })
      .attr('stroke-dasharray', (d: Date) => {
        switch (d.getMinutes()) {
          case 0:
            return 'none';
          case 30:
            return d3
              .range(yScaleLeft.domain().length * 2)
              .map(() => yScaleLeft.step() / 2)
              .join(',');
          case 15:
          case 45:
            return d3
              .range(yScaleLeft.domain().length * 2)
              .map((_, i) => (i % 2 === 0 ? yScaleLeft.step() / 4 : (yScaleLeft.step() * 3) / 4))
              .join(',');
          default:
            return 'none';
        }
      });

    g.selectAll<SVGGElement, Date>('.tick')
      .classed('day-break', (date) => {
        const time = timezone ? moment(date).tz(timezone) : moment(date);

        return time.hours() === 0 && time.minutes() === 0;
      })
      .selectAll<SVGTextElement, Date>('text')
      .attr('style', (date: Date) => {
        const time = timezone ? moment(date).tz(timezone) : moment(date);

        switch (time.hours()) {
          case 0:
          case 12:
            return 'font-weight: bold; font-size: 12px;';
          default:
            return null;
        }
      });
  };

  const pathStroke = (xScale: d3.ScaleTime<number, number>) => (events: ChartEvent[]) => {
    const dashWidth = 3;

    return events
      .reduce<(number | number[])[]>((acc, event, index) => {
        if (index !== events.length - 1) {
          const dx = xScale(events[index + 1].timestamp) - xScale(event.timestamp);
          const dy = Math.abs(yScaleLeft(Y(events[index + 1].type))! - yScaleLeft(Y(event.type))!);

          if (event.type !== 'DR_IND_YM' && event.type !== 'DR_IND_PC') {
            if (acc.length > 0 && !Array.isArray(acc[acc.length - 1])) {
              acc[acc.length - 1] = (acc[acc.length - 1] as number) + dx + dy;
            } else {
              acc.push(dx + dy);
            }
            return acc;
          }

          const numberOfDashes = dx / dashWidth;
          const nearestOddNumberToDown =
            numberOfDashes < 1
              ? 1
              : Math.floor(numberOfDashes) % 2 === 0
                ? (Math.floor(numberOfDashes) / 2) * 2 - 1
                : Math.floor(numberOfDashes);
          const remainingPart = (numberOfDashes - nearestOddNumberToDown) * dashWidth;
          if (acc.length > 0 && !Array.isArray(acc[acc.length - 1])) {
            acc[acc.length - 1] = (acc[acc.length - 1] as number) + remainingPart / 2;
          } else {
            acc.push(remainingPart / 2);
          }
          acc.push(d3.range(nearestOddNumberToDown).map(() => dashWidth));
          acc.push(dy + remainingPart / 2);

          return acc;
        }

        return acc;
      }, [])
      .flat()
      .join(', ');
  };

  const getFilteredData = useMemo(
    () => (xScale: d3.ScaleTime<number, number>) => {
      const [
        minX,
        maxX,
      ] = xScale.domain();
      const minIndex = X.findIndex((timestamp) => timestamp >= minX.getTime());
      const maxIndex = R.findLastIndex<number>((timestamp) => timestamp <= maxX.getTime())(X);

      const sliced = [...events.slice(minIndex === 0 ? 0 : minIndex - 1, maxIndex + 2)];
      sliced[0] = {
        ...sliced[0],
        timestamp: minX.getTime(),
      };
      sliced[sliced.length - 1] = {
        ...sliced[sliced.length - 1],
        timestamp: maxX.getTime(),
      };

      return sliced;
    },
    [
      events,
      X,
    ]
  );

  interface PathColorStop {
    stop: number;
    color: string;
  }

  let xContextScale = useRef(xScale);
  function updateContext(xScale: d3.ScaleTime<number, number>) {
    xContextScale.current = xScale;
    const data = getFilteredData(xScale);
    const pathColorStops = data.reduce((acc, event, index) => {
      if (index === 0) {
        acc.push({
          stop: xScale(event.timestamp) / innerWidth,
          color: event.color,
        });
      } else if (index === data.length - 1) {
        const stop = xScale(event.timestamp) / innerWidth;
        const color = acc[acc.length - 1].color;
        acc.push({
          stop,
          color,
        });
      } else if (data[index - 1].color !== event.color) {
        const stop = xScale(event.timestamp) / innerWidth;
        acc.push({
          stop,
          color: data[index - 1].color,
        });
        acc.push({
          stop,
          color: event.color,
        });
      }

      return acc;
    }, [] as PathColorStop[]);

    d3.select<SVGGElement, ChartEvent>('.context > .x-axis')
      .call(xAxis(xScale).tickSizeInner(-(height - margin.top - margin.bottom)))
      .call(xGrid);

    const days = d3.selectAll<SVGGElement, Date>('.context > .x-axis > .tick.day-break').data();
    if (!days.map((d) => d.getTime()).includes(data[0].timestamp)) {
      days.unshift(new Date(data[0].timestamp));
    }
    if (!days.map((d) => d.getTime()).includes(data[data.length - 1].timestamp)) {
      days.push(new Date(data[data.length - 1].timestamp));
    }
    const dayPairs = d3.pairs(days);

    const shades = d3
      .select<SVGGElement, undefined>('.context > .x-axis')
      .selectAll<SVGRectElement, [Date, Date]>('rect')
      .data(dayPairs);

    shades.exit().remove();

    shades
      .enter()
      .append('rect')
      .lower()
      .merge(shades)
      .attr('class', 'shade')
      .attr('x', (dates) => xScale(dates[0]))
      .attr('y', -(height - margin.top - margin.bottom))
      .attr('width', (dates) => xScale(dates[1]) - xScale(dates[0]))
      .attr('height', height - margin.top - margin.bottom)
      .attr(
        'fill',
        ([
          start,
        ]) => {
          const date = timezone ? moment(start).tz(timezone) : moment(start);
          return date.date() % 2 === 0 ? 'transparent' : 'grey';
        }
      )
      .attr('opacity', 0.1);

    d3.select<SVGGElement, ChartEvent>('.context > g.y-axis-right')
      .call(yAxisRight(yScaleRight.copy().domain(yDomainRight(data.slice(0, -1)))))
      .call((g) =>
        g
          .selectAll<SVGTextElement, string>('text')
          .attr('dy', 0)
          .each(function (text: string) {
            const element = d3.select(this);
            const parts = text.split(' / ');
            element.text('');
            for (let i = 0; i < parts.length; i++) {
              const part = parts[i];
              const span = element.append('tspan').text(part);
              if (i > 0) {
                span.attr('x', element.attr('x')).attr('dy', '1em');
              }
            }
          })
      );
    d3.select<SVGPathElement, ChartEvent>('.context > path')
      .datum(data)
      .attr('d', line(xScale, yScaleLeft))
      .attr('stroke-dasharray', pathStroke(xScale));

    d3.select<SVGLinearGradientElement, PathColorStop>('.context > defs > #gradient')
      .selectAll<SVGStopElement, PathColorStop>('stop')
      .data(pathColorStops)
      .join('stop')
      .attr('offset', (value) => value.stop)
      .attr('stop-color', (value) => value.color);
  }

  const gb = useRef<d3.Selection<SVGGElement, ChartEvent, HTMLElement, any> | null>(null);
  const brush = useRef<d3.BrushBehavior<ChartEvent> | null>(null);
  const focus = useD3(
    (svg) => {
      if (events.length === 0) {
        return;
      }

      const patchColorStops = events.reduce((acc, event, index) => {
        if (index === 0) {
          acc.push({
            stop: xScale(event.timestamp) / innerWidth,
            color: event.color,
          });
        } else if (index === events.length - 1) {
          const stop = xScale(event.timestamp) / innerWidth;
          if (acc[acc.length - 1].color !== event.color) {
            acc.push({
              stop,
              color: acc[acc.length - 1].color,
            });
          }
          acc.push({
            stop,
            color: event.color,
          });
        } else if (events[index - 1].color !== event.color) {
          const stop = xScale(event.timestamp) / innerWidth;
          acc.push({
            stop,
            color: events[index - 1].color,
          });
          acc.push({
            stop,
            color: event.color,
          });
        }

        return acc;
      }, [] as PathColorStop[]);

      const xAxisGroup = d3.select<SVGGElement, ChartEvent>('.focus > g.x-axis');
      const path = d3.select<SVGPathElement, ChartEvent>('.focus > path');
      const gradient = d3.select<SVGLinearGradientElement, PathColorStop>('.focus > linearGradient');

      gb.current = d3.select<SVGGElement, ChartEvent>('.focus > g.brush');
      const defaultSelection = [
        xScale.range()[0],
        xScale(d3.timeDay.offset(xScale.domain()[0], 1)),
      ] as [number, number];

      svg.on('input', (event) => {
        const focus = event.target;
        const [
          minX,
          maxX,
        ] = focus.value;
        updateContext(
          xScale.copy().domain([
            minX,
            maxX,
          ])
        );
      });

      brush.current = d3
        .brushX<ChartEvent>()
        .extent([
          [
            margin.left,
            0.5,
          ],
          [
            width - margin.right,
            focusHeight - margin.bottom + 0.5,
          ],
        ])
        .on('brush', brushed)
        .on('end', brushended);

      function brushed(event: D3BrushEvent<ChartEvent>) {
        if (event.selection) {
          svg.property('value', (event.selection as [number, number]).map(xScale.invert, xScale));
          svg.dispatch('input');
        }
      }

      function brushended(event: D3BrushEvent<ChartEvent>) {
        if (!event.selection && gb.current && brush.current) {
          gb.current.call(brush.current.move, defaultSelection);
        }
      }

      xAxisGroup.call(
        xAxis(xScale)
          .tickValues(xFocusTickValues)
          .tickFormat((d) => {
            const time = timezone ? moment(d).tz(timezone) : moment(d);
            if (time.hours() === 0) {
              return time.format('MMM D');
            }

            return '';
          })
      );

      path.datum<ChartEvent[]>(events).attr(
        'd',
        line(
          xScale,
          yScaleLeft.copy().range([
            focusHeight - margin.bottom,
            4,
          ])
        )
      );

      gradient
        .selectAll('stop')
        .data(patchColorStops)
        .join('stop')
        .attr('offset', (value) => value.stop)
        .attr('stop-color', (value) => value.color);

      if (svg.property('value') === undefined && gb.current && brush.current) {
        gb.current.call(brush.current).call(brush.current.move, defaultSelection);
      }

      return () => {
        svg.on('input', null);
        if (brush.current) {
          brush.current.on('brush', null);
          brush.current.on('end', null);
        }
      };
    },
    [
      events,
      xScale,
    ]
  );

  const hoveredEvent = useRef<ChartEvent | undefined>(undefined);
  const highlightEvent = useCallback(
    (event?: ChartEvent, dispatchHoverEvent = false) => {
      if (hoveredEvent.current === event) {
        return;
      }

      const highlight = d3.select('.context > .highlight');
      hoveredEvent.current = event;
      if (event) {
        const scale = xContextScale.current;
        const xValue = event.timestamp;
        const yValue = Y(event.type);

        highlight.attr('cx', scale(xValue)).attr('cy', yScaleLeft(yValue)!).style('opacity', 1);
      } else {
        highlight.style('opacity', 0);
      }

      if (dispatchHoverEvent) {
        onEventHover(hoveredEvent.current);
      }
    },
    [
      Y,
      onEventHover,
      yScaleLeft,
    ]
  );

  useEffect(() => {
    if (!focus.current || !gb.current || !brush.current) {
      return;
    }

    if (eventIdToHighlight !== null) {
      const focusSVG = d3.select(focus.current);
      const event = events.find((event) => event.id === eventIdToHighlight);
      if (event) {
        const timestamp = event?.timestamp;
        const startX = xScale.clamp(true)(d3.timeHour.offset(moment(timestamp).toDate(), -12));
        const endX = xScale(d3.timeDay.offset(xScale.invert(startX), 1));
        const [
          currentMinX,
          currentMaxX,
        ] = focusSVG.property('value') as [Date?, Date?];

        if (!(currentMinX && currentMaxX && currentMinX.getTime() <= timestamp && timestamp <= currentMaxX.getTime())) {
          highlightEvent(undefined);
          gb.current
            .call(brush.current)
            .interrupt('brush-move')
            .transition('brush-move')
            .delay(100)
            .duration(750)
            .call(brush.current.move, [
              startX,
              endX,
            ])
            .end()
            .then(() => highlightEvent(event));
        } else {
          highlightEvent(event);
        }
      } else {
        highlightEvent(undefined);
      }
    } else {
      highlightEvent(undefined);
    }
  }, [
    eventIdToHighlight,
    events,
    focus,
    highlightEvent,
    xScale,
  ]);

  const bisector = d3.bisector<ChartEvent, number>((d) => d.timestamp).left;

  const chart = useD3(() => {
    const yAxisLeftGroup = d3.select<SVGGElement, ChartEvent>('.context > g.y-axis-left');
    const overlay = d3.select('.context > .overlay');
    const path = d3.select<SVGPathElement, ChartEvent[]>('.context > path');

    overlay
      .on('mousemove', function (event: MouseEvent) {
        const pos = d3.pointer(event);
        const scale = xContextScale.current;
        const hoveredTimestamp = scale.invert(pos[0]).getTime();
        const events = path.datum();
        const index = bisector(events, hoveredTimestamp, 1);
        const closestEvent = events[index - 1];

        highlightEvent(closestEvent, true);
      })
      .on('mouseleave', () => {
        highlightEvent(undefined, true);
      });

    yAxisLeftGroup.call(yAxisLeft(yScaleLeft).tickSizeInner(-width + margin.left + margin.right)).call((g) =>
      g
        .selectAll('.tick > line')
        .attr('stroke-opacity', (d) => (d === 'DS_OFF' ? 1 : 0.1))
        .attr('transform', `translate(0, ${-yScaleLeft.padding() * yScaleLeft.step()})`)
    );

    return () => {
      overlay.on('mousemove', null);
      overlay.on('mouseleave', null);
    };
  }, []);

  return (
    <>
      <svg ref={chart} className="context" viewBox={`0,0,${width},${height}`} style={{ display: 'block' }}>
        <defs>
          <clipPath id="clip">
            <rect x={margin.left} y={margin.top} height={height - margin.top} width={innerWidth}></rect>
          </clipPath>
          <linearGradient id="gradient" gradientUnits="userSpaceOnUse" x1={0} x2={innerWidth}></linearGradient>
        </defs>
        <g className="x-axis" transform={`translate(0,${height - margin.bottom})`} />
        <g className="y-axis-left" transform={`translate(${margin.left},0)`} />
        <g className="y-axis-right" transform={`translate(${width - margin.right},0)`} style={{ fontSize: '11px' }} />
        <path
          clipPath="url(#clip)"
          fill="none"
          stroke="url(#gradient)"
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
          strokeOpacity={strokeOpacity}
        />
        <rect
          className="overlay"
          fill="transparent"
          x={margin.left}
          y={margin.top}
          height={height - margin.top - margin.bottom}
          width={innerWidth}
        ></rect>
        <circle
          className="highlight"
          r="4"
          stroke={color}
          fill={color}
          strokeWidth={strokeWidth}
          cx="0"
          cy="0"
          opacity="0"
        ></circle>
      </svg>
      <svg ref={focus} className="focus" viewBox={`0,0,${width},${focusHeight}`} style={{ display: 'block' }}>
        <g className="x-axis" transform={`translate(0,${focusHeight - margin.bottom})`} />
        <linearGradient id="focusGradient" gradientUnits="userSpaceOnUse" x1={0} x2={innerWidth}></linearGradient>
        <path
          fill="none"
          stroke="url(#focusGradient)"
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
          strokeOpacity={strokeOpacity}
        />
        <g className="brush" />
      </svg>
    </>
  );
};

export default DriverLogsChart;
