export const SPEEDING_SPEED_DEFAULT_THRESHOLD = 70;
export const SPEEDING_SPEED_STATE_THRESHOLD = {
  AL: 70,
  AK: 65,
  AZ: 75,
  AR: 70,
  CA: 55,
  CO: 75,
  CT: 65,
  DE: 65,
  FL: 70,
  GA: 70,
  HI: 60,
  ID: 70,
  IL: 70,
  IN: 65,
  IA: 70,
  KS: 75,
  KY: 70,
  LA: 70,
  ME: 70,
  MD: 70,
  MA: 65,
  MI: 65,
  MN: 70,
  MS: 70,
  MO: 70,
  MT: 70,
  NE: 75,
  NV: 75,
  NH: 65,
  NJ: 65,
  NM: 75,
  NY: 65,
  NC: 70,
  ND: 75,
  OH: 70,
  OK: 75,
  OR: 60,
  PA: 65,
  RI: 65,
  SC: 70,
  SD: 75,
  TN: 70,
  TX: 75,
  UT: 75,
  VT: 65,
  VA: 65,
  WA: 60,
  WV: 70,
  WI: 70,
  WY: 75,
};
