import { InputNumber, Modal } from 'antd';
import React, { useRef } from 'react';

interface OdometerOffsetFormProps {
  isVisible: boolean;
  onConfirm: (offset: number) => void;
  onCancel: () => void;
  message?: string;
}

const OdometerOffsetForm: React.FC<OdometerOffsetFormProps> = ({ isVisible, onConfirm, onCancel, message }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Modal
      visible={isVisible}
      title="Adjust odometer"
      destroyOnClose
      onCancel={onCancel}
      onOk={() => {
        onConfirm(inputRef.current ? Number(inputRef.current.value) : 0);
      }}
    >
      {message && <div style={{marginBottom: '10px'}}>{message}</div>}
      <InputNumber ref={inputRef} defaultValue={0} />
    </Modal>
  );
};

export default OdometerOffsetForm;
