import { CompanyPayload } from 'interfaces';
import { useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { createSubscriptiveSlice, ResponsePayload } from 'slices/subscriptive';
import { Socket } from 'socket.io-client';
import { RootState } from '../store/reducer';
import { emitAsync, emitAsyncOrdered } from '../utils/socket';
import { Violation } from '../views/violations';
import { setError } from './errors';

const {
  select,
  selectDictionary,
  unsubscribe,
  reducer,
  reconnect,
  onPublish,
  subscribe,
  initialState,
  selectResourceList,
  slice,
} = createSubscriptiveSlice({
  // @ts-expect-error _id is not part of BaseModal
  idProp: '_id',
  volatile: true,
  payloadType: CompanyPayload,
  name: 'companies',
  deletedFilterFn(resource): boolean {
    return resource.deletedAt != null;
  },
  reducers: {},
});

const { setLoading } = slice.actions;

const refresh =
  (data?: Record<string, unknown>) =>
  async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket) => {
    const socket = getSocket();

    dispatch(setLoading(true));
    const { status, msg } = await emitAsyncOrdered(
      socket,
      'companies',
      'companies:refresh',
      {
        ...data,
      },
      false
    );
    dispatch(setLoading(false));
    if (status === 'error') {
      dispatch(setError({ status: status, msg: msg }));
    }
  };

export const useCompanies = () => {
  const { loading: companiesLoading, subscribed: companiesSubscribed } = useSelector(select);
  const companies = useSelector(selectResourceList) as CompanyPayload[];
  const companiesById = useSelector(selectDictionary) as Record<string, CompanyPayload>;

  return {
    companies,
    companiesById,
    companiesLoading,
    companiesSubscribed,
  };
};

export default slice.reducer;

const getViolations =
  () =>
  async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket): Promise<Violation[]> => {
    const socket = getSocket();
    const response = await emitAsync<ResponsePayload<Violation[]>>(socket, 'companies/violations', {});

    if (response.status !== 'ok') {
      dispatch(setError({ status: response.status, msg: response.msg }));
    }

    return response.data;
  };

const setViolationNotifications =
  (time: Date | null, message: string, shortcut: string | null) =>
  async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket) => {
    const socket = getSocket();
    const response = await emitAsync<ResponsePayload>(socket, 'companies/violation:set-notification', {
      time,
      message,
      shortcut,
    });

    if (response.status !== 'ok') {
      dispatch(setError({ status: response.status, msg: response.msg }));
    }
  };

const getViolationNotificationSettings =
  () => async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket) => {
    const socket = getSocket();
    const response = await emitAsync<ResponsePayload<{ time?: string; message: string; shortcut?: string }>>(
      socket,
      'companies/violation:get-notification',
      {}
    );

    if (response.status !== 'ok') {
      dispatch(setError({ status: response.status, msg: response.msg }));
    }

    return response.data;
  };

export {
  unsubscribe,
  reducer,
  reconnect,
  onPublish,
  subscribe,
  initialState,
  refresh,
  getViolations,
  setViolationNotifications,
  getViolationNotificationSettings,
};
