import { ApiOutlined, SyncOutlined, WifiOutlined } from '@ant-design/icons';
import { Button, Space, Tooltip } from 'antd';
import { FC, HTMLAttributes } from 'react';
import { DriverStatus } from '../interfaces';
import DonutProgressChart from './DonutProgressChart';

interface OwnProps extends HTMLAttributes<HTMLDivElement> {
  status: DriverStatus;
  onReloadData: () => void;
  isLoading: boolean;
}

const DriverStatusChart: FC<OwnProps> = ({ status, isLoading, onReloadData, ...otherProps }) => {
  return (
    <div
      style={{
        display: 'grid',
        gap: '1em',
        gridAutoFlow: 'column',
        gridAutoColumns: '1fr',
        height: '58px',
        paddingRight: '30px',
      }}
      {...otherProps}
    >
      <DonutProgressChart
        name="Break"
        value={
          status.breakTime.limitTime - status.breakTime.accumulatedTime > 0
            ? status.breakTime.limitTime - status.breakTime.accumulatedTime
            : 0
        }
        total={status.breakTime.limitTime}
        color="#edb540"
      />
      <DonutProgressChart
        name="Drive"
        value={
          status.driveTime.limitTime - status.driveTime.accumulatedTime > 0
            ? status.driveTime.limitTime - status.driveTime.accumulatedTime
            : 0
        }
        total={status.driveTime.limitTime}
        color="#499c5b"
      />
      <DonutProgressChart
        name="Shift"
        value={
          status.shiftTime.limitTime - status.shiftTime.accumulatedTime > 0
            ? status.shiftTime.limitTime - status.shiftTime.accumulatedTime
            : 0
        }
        total={status.shiftTime.limitTime}
        color="#7653df"
      />
      <DonutProgressChart
        name="Cycle"
        value={
          status.cycleTime.limitTime - status.cycleTime.accumulatedTime > 0
            ? status.cycleTime.limitTime - status.cycleTime.accumulatedTime
            : 0
        }
        total={status.cycleTime.limitTime}
        color="#de6f2d"
      />
      <Space direction="vertical" size="small" style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Tooltip title="Refresh HoS">
          <Button size="small" type="link" onClick={() => onReloadData()} icon={<SyncOutlined spin={isLoading} />} />
        </Tooltip>
        {status.eldStatus === 'offline' ? (
          <ApiOutlined style={{ width: '24px', height: '24px' }} title="Offline" />
        ) : status.eldStatus === 'connected' ? (
          <WifiOutlined
            style={{ width: '24px', height: '24px', color: 'green', fontSize: '1.1rem' }}
            title="Connected"
          />
        ) : (
          <WifiOutlined
            style={{ width: '24px', height: '24px', color: 'red', fontSize: '1.1rem' }}
            title="Disconnected"
          />
        )}
      </Space>
    </div>
  );
};

export default DriverStatusChart;
