import { VehiclePayload } from 'interfaces';
import * as r from 'ramda';
import { useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { createRelativeSubscriptiveSlice } from 'slices/subscriptive/relative';
import { Socket } from 'socket.io-client';
import { RootState } from '../store/reducer';
import { emitAsyncOrdered } from '../utils/socket';
import { setError } from './errors';

const { select, unsubscribe, reducer, reconnect, onPublish, subscribe, selectChildResourceList, slice } =
  createRelativeSubscriptiveSlice({
    name: 'vehicles',
    // @ts-expect-error _id does not exist
    idProp: '_id',
    payloadType: VehiclePayload,
    parentName: 'companies',
    parentSingleName: 'company',
    deletedFilterFn(resource): boolean {
      return resource.deletedAt != null;
    },
    reducers: {},
  });

const { setLoading } = slice.actions;

const refresh =
  (companyId: string) => async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket) => {
    const socket = getSocket();

    dispatch(setLoading({ parentId: companyId, loading: true }));
    const { status, msg } = await emitAsyncOrdered(
      socket,
      'vehicles',
      'company/vehicles:refresh',
      {
        companyId: companyId,
      },
      false
    );
    dispatch(setLoading({ parentId: companyId, loading: false }));
    if (status === 'error') {
      dispatch(setError({ status: status, msg: msg }));
    }
  };

export default slice.reducer;

export const useVehicles = (accountId: string | null) => {
  const {
    resourceDictionary: vehiclesById,
    loading: vehiclesLoading,
    subscribed: vehiclesSubscribed,
  } = useSelector(r.partial(select, [accountId])) || {};

  let vehicles = useSelector(r.partial(selectChildResourceList, [accountId]));
  let onlyVehiclesById = vehiclesById;

  if (vehicles instanceof Array && vehicles.length > 0) {
    onlyVehiclesById = r.indexBy(
      r.prop('_id'),
      Object.values(vehiclesById as object).filter((vehicle) => vehicle.type.id === 'Vehicle')
    );
    vehicles = (vehicles as VehiclePayload[]).filter((vehicle) => vehicle.type.id === 'Vehicle');
  }

  return {
    vehicles: vehicles as VehiclePayload[] | undefined,
    vehiclesById: onlyVehiclesById as Record<string, VehiclePayload> | undefined,
    vehiclesLoading: vehiclesLoading,
    vehiclesSubscribed: vehiclesSubscribed,
  };
};

export { unsubscribe, reducer, reconnect, onPublish, subscribe, refresh };
