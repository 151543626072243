import PQueue from 'p-queue';
import { Socket } from 'socket.io-client';

const queues: Record<string, PQueue> = {};

const errorMessages = new Map<string, string>([
  [
    'driver/get-daily-logs',
    'Loading profile form data did not finish on time. If you need updated data, please Close and Reopen the Profile Form window.',
  ],
  [
    'driver/status:get',
    'Loading driver Hours of Service did not finish on time. If you need updated data, please click the refresh button on the Hours of Service widget.',
  ],
]);

export const emitAsyncOrdered = <T>(
  socket: Socket,
  resourceId: string,
  event: string,
  data?: any,
  withTimeout = true
): Promise<T | any> => {
  if (!queues[resourceId]) {
    queues[resourceId] = new PQueue({ concurrency: 1, autoStart: true });
  }
  const queue = queues[resourceId];
  return queue.add(() => {
    return new Promise<T | any>(function (resolve, reject) {
      let timeout: any;
      if (socket.connected && withTimeout) {
        timeout = setTimeout(() => {
          console.log('Timeout in', event);
          resolve({
            status: 'timeout',
            msg: errorMessages.has(event) ? errorMessages.get(event) : `timeout in ${event}`,
          });
        }, 60000);
      }

      console.log(`Emitting ${event}`, data);
      socket.emit(event, data, (newData: T) => {
        timeout && clearTimeout(timeout);
        console.log(`Ack: ${event}`, newData);
        resolve(newData);
      });
    });
  });
};

export const emitAsync = <T>(socket: Socket, event: string, data?: any, withTimeout = true): Promise<T | any> => {
  return new Promise<T | any>(function (resolve, reject) {
    let timeout: any;
    if (socket.connected && withTimeout) {
      timeout = setTimeout(() => {
        console.log('Timeout in', event);
        resolve({
          status: 'timeout',
          msg: errorMessages.has(event) ? errorMessages.get(event) : `timeout in ${event}`,
        } as any);
      }, 60000);
    }

    console.log(`Emitting ${event}`, data);
    socket.emit(event, data, (newData: T) => {
      timeout && clearTimeout(timeout);
      console.log(`Ack: ${event}`, newData);
      resolve(newData);
    });
  });
};
