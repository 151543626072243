import { DailyLog, DriverPayload, DriverStatus } from 'interfaces';
import * as r from 'ramda';
import { useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { createRelativeSubscriptiveSlice, RelativeSubscriptiveResourceList } from 'slices/subscriptive/relative';
import { Socket } from 'socket.io-client';
import { RootState } from '../store/reducer';
import { emitAsync, emitAsyncOrdered } from '../utils/socket';
import { setError } from './errors';
import { ResponsePayload } from './subscriptive';

export type DriversRootState = {
  [k: string]: RelativeSubscriptiveResourceList<DriverPayload>;
};

const { select, unsubscribe, reducer, reconnect, onPublish, subscribe, selectChildResourceList, slice, resetResource } =
  createRelativeSubscriptiveSlice({
    name: 'drivers',
    // @ts-expect-error _id does not exist
    idProp: '_id',
    payloadType: DriverPayload,
    parentName: 'companies',
    parentSingleName: 'company',
    deletedFilterFn(resource): boolean {
      return resource.deletedAt != null;
    },
    reducers: {},
  });

export default slice.reducer;

const { setLoading } = slice.actions;

const refresh =
  (companyId: string) => async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket) => {
    const socket = getSocket();

    dispatch(setLoading({ parentId: companyId, loading: true }));
    dispatch(resetResource({ parentId: companyId }));
    const { status, msg } = await emitAsyncOrdered(
      socket,
      'company/drivers',
      'company/drivers:refresh',
      {
        companyId,
      },
      false
    );
    dispatch(setLoading({ parentId: companyId, loading: false }));
    if (status === 'error') {
      dispatch(setError({ status: status, msg: msg }));
    }
  };

export const useDrivers = (accountId: string | null) => {
  const {
    resourceDictionary: driversById,
    loading: driversLoading,
    subscribed: driversSubscribed,
  } = useSelector(r.partial(select, [accountId])) || {};

  let drivers = useSelector(r.partial(selectChildResourceList, [accountId]));
  let onlyDriversById = driversById;

  // TODO: return only drivers from backend
  if (drivers instanceof Array && drivers.length > 0) {
    onlyDriversById = r.indexBy(
      r.prop('_id'),
      Object.values(driversById as object).filter((driver) => driver.role.id === 'DRIVER')
    );
    drivers = (drivers as DriverPayload[]).filter((driver) => driver.role.id === 'DRIVER');
  }

  return {
    drivers: drivers as DriverPayload[] | undefined,
    driversById: onlyDriversById as Record<string, DriverPayload> | undefined,
    driversLoading,
    driversSubscribed,
  };
};

const getDriverStatus =
  (id: string, companyId: string, fresh?: boolean) =>
  async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket): Promise<DriverStatus> => {
    const socket = getSocket();
    const response = await emitAsync<ResponsePayload<DriverStatus>>(socket, `driver/status:get`, {
      id,
      companyId,
      fresh,
    });

    if (response.status !== 'ok') {
      dispatch(setError({ status: response.status, msg: response.msg }));
    }

    return response.data;
  };

const getDailyLogs =
  (id: string, companyId: string, from: string, to: string) =>
  async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket): Promise<DailyLog[]> => {
    const socket = getSocket();
    const response = await emitAsync<ResponsePayload<DailyLog[]>>(socket, 'driver/get-daily-logs', {
      id,
      companyId,
      from,
      to,
    });

    if (response.status !== 'ok') {
      dispatch(setError({ status: response.status, msg: response.msg }));
    }

    return response.data;
  };

const updateDailyLogs =
  (id: string, companyId: string, logs: DailyLog[]) =>
  async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket): Promise<any> => {
    const socket = getSocket();
    const response = await emitAsync(socket, 'driver/update-daily-logs', {
      id,
      companyId,
      logs,
    });
  };

export {
  unsubscribe,
  reducer,
  reconnect,
  onPublish,
  subscribe,
  getDriverStatus,
  getDailyLogs,
  updateDailyLogs,
  refresh,
};
