import { createSlice, Draft, PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import { ClassConstructor } from 'class-transformer';
import { ValidationError } from 'class-validator';
import { BaseModel } from 'interfaces';
import * as R from 'ramda';
import { Dictionary } from 'ramda';
import { Dispatch } from 'redux';
import { Socket } from 'socket.io-client';
import { emitAsyncOrdered } from 'utils/socket';
import { setError } from '../errors';

export interface ResponsePayload<T = null> {
  status: string;
  errors?: ValidationError[];
  data?: T;
  msg?: string;
}

export interface SubscriptiveResourceList<T> {
  loading: boolean;
  subscribed: boolean;
  lastUpdatedAt: string | undefined | null;
  resourceDictionary: Dictionary<T>;
}

export type ObjectPropertiesOfType<Obj, Type> = {
  [Key in keyof Obj]: Obj[Key] extends Type ? Key : never;
}[keyof Obj];

export const createSubscriptiveSlice = ({
  name,
  alias,
  deletedFilterFn,
  payloadType,
  params,
  reducers,
  idProp,
  volatile,
}: {
  deletedFilterFn: (resource: BaseModel) => boolean;
  name: string;
  params?: any;
  alias?: string;
  payloadType: ClassConstructor<BaseModel>;
  reducers: ValidateSliceCaseReducers<
    SubscriptiveResourceList<BaseModel>,
    SliceCaseReducers<SubscriptiveResourceList<BaseModel>>
  >;
  volatile?: boolean;
  idProp: Extract<ObjectPropertiesOfType<BaseModel, string>, string>;
}) => {
  type RootState = {
    [k: string]: SubscriptiveResourceList<BaseModel>;
  };

  const selectResourceList = (state: RootState) =>
    R.sortWith([R.descend(R.prop('createdAt'))], R.values(state[name].resourceDictionary));

  const initialState: SubscriptiveResourceList<BaseModel> = {
    resourceDictionary: {},
    lastUpdatedAt: null,
    subscribed: false,
    loading: false,
  };

  const slice = createSlice({
    name: name,
    initialState,
    reducers: {
      resetResource: (state: Draft<SubscriptiveResourceList<BaseModel>>) => {
        state.resourceDictionary = {};
      },
      setResourceDictionary: (
        draft: SubscriptiveResourceList<BaseModel>,
        { payload: dictionary }: PayloadAction<Dictionary<BaseModel>>
      ) => {
        draft.resourceDictionary = dictionary;
      },
      onPublish: (state, { payload }: PayloadAction<BaseModel[]>) => {
        const resources: BaseModel[] = payload;
        console.log(`${name} onPublish`, resources);

        const deletedResourceIds: string[] = R.pipe<BaseModel[], BaseModel[], string[]>(
          R.filter(deletedFilterFn),
          R.map(R.prop(idProp))
        )(resources) as string[];

        const updatedResources: BaseModel[] = R.filter(R.complement(deletedFilterFn), resources);

        Object.assign(state.resourceDictionary, R.indexBy(R.prop(idProp), updatedResources));

        for (const resourceId of deletedResourceIds) {
          delete state.resourceDictionary[resourceId];
        }

        const lastUpdatedPatch = R.pipe<BaseModel[], BaseModel[], BaseModel>(
          R.sortBy(R.prop('updatedAt')),
          R.last
        )(Object.values(state.resourceDictionary));

        state.lastUpdatedAt = lastUpdatedPatch?.updatedAt;
      },

      setLoading: (state, { payload: loading }: PayloadAction<boolean>) => {
        state.loading = loading;
      },
      setSubscribed: (state, { payload: subscribed }: PayloadAction<boolean>) => {
        state.subscribed = subscribed;
      },
      ...reducers,
    },
  });

  const { onPublish, setLoading, setSubscribed, setResourceDictionary, resetResource } = slice.actions;
  const select = (state: RootState) => state[name];
  const selectDictionary = (state: RootState) => state[name].resourceDictionary;

  const unsubscribe =
    (data?: Record<string, unknown>) =>
    async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket) => {
      const socket = getSocket();
      dispatch(setLoading(true));
      socket.off(`${name}:publish`);
      if (getSocket().connected) {
        await emitAsyncOrdered(socket, alias || name, `${alias || name}:unsubscribe`, {
          ...params,
          ...data,
        });
      }

      if (volatile) {
        dispatch(setResourceDictionary({}));
      }
      dispatch(setLoading(false));
      dispatch(setSubscribed(false));
    };

  const subscribe =
    (data?: Record<string, unknown>) =>
    async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket) => {
      const socket = getSocket();
      const { lastUpdatedAt } = select(getState());

      dispatch(setLoading(true));
      socket.off(`${alias || name}:publish`);
      socket.on(`${alias || name}:publish`, async (data: BaseModel[]) => {
        await dispatch(onPublish(data));
      });
      const { status, msg } = await emitAsyncOrdered(
        socket,
        alias || name,
        `${alias || name}:subscribe`,
        {
          ...(lastUpdatedAt && { lastUpdatedAt }),
          ...params,
          ...data,
        },
        false
      );
      dispatch(setSubscribed(status === 'ok'));
      dispatch(setLoading(false));
      if (status === 'error') {
        dispatch(setError({ status: status, msg: msg }));
      }
    };

  const reconnect = () => async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket) => {
    const socket = getSocket();
    const state = select(getState());
    const subscribed = state?.subscribed;
    if (subscribed) {
      dispatch(setSubscribed(false));
      // dispatch(setResourceDictionary({}));
      dispatch(subscribe());
    }
  };

  // const create = (data: TInCreate) => async (
  //   dispatch: Dispatch<any>,
  //   getState: () => RootState,
  //   getSocket: () => Socket
  // ): Promise<ResponsePayload<S>> => {
  //   const socket = getSocket();
  //   dispatch(setLoading(true));
  //   const response = await emitAsync<ResponsePayload<S>>(
  //     socket,
  //     `${name}:create`,
  //     data
  //   );
  //   dispatch(setLoading(false));
  //   if (response.status !== "ok") {
  //   }
  //   return response;
  // };
  //
  // const update = (data: TInUpdate) => async (
  //   dispatch: Dispatch<any>,
  //   getState: () => RootState,
  //   getSocket: () => Socket
  // ): Promise<ResponsePayload<S>> => {
  //   const socket = getSocket();
  //   dispatch(setLoading(true));
  //   const response = await emitAsync<ResponsePayload<S>>(
  //     socket,
  //     `${name}:update`,
  //     data
  //   );
  //   dispatch(setLoading(false));
  //   return response;
  // };
  //
  // const remove = (data: { id: string }) => async (
  //   dispatch: Dispatch<any>,
  //   getState: () => RootState,
  //   getSocket: () => Socket
  // ): Promise<ResponsePayload<S>> => {
  //   const socket = getSocket();
  //   dispatch(setLoading(true));
  //   const response = await emitAsync<ResponsePayload<S>>(
  //     socket,
  //     `${name}:remove`,
  //     data
  //   );
  //   dispatch(setLoading(false));
  //   return response;
  // };

  const reducer = slice.reducer;
  return {
    select,
    selectDictionary,
    reconnect,
    resetResource,
    unsubscribe,
    reducer,
    onPublish,
    subscribe,
    selectResourceList,
    slice,
    initialState,
  };
};

// export const withCreateUpdateActions = <
//   S,
//   TInCreate extends Partial<S>,
//   TInUpdate extends Partial<S> & { id: string | number }
// >({
//   name,
//   deletedFilterFn,
//   reducers,
// }: {
//   deletedFilterFn: (resource: S) => boolean;
//   name: string;
//   reducers: ValidateSliceCaseReducers<
//     SubscriptiveResourceList<S>,
//     SliceCaseReducers<SubscriptiveResourceList<S>>
//   >;
// }) => {
//   type RootState = {
//     [k: string]: SubscriptiveResourceList<S>;
//   };
//
//   const selectResourceList = (state: RootState) =>
//     R.sortBy(R.prop("createdAt"), R.values(state[name].resourceDictionary));
//
//   const initialState: SubscriptiveResourceList<S> = {
//     resourceDictionary: {},
//     subscribed: false,
//     errorsDictionary: {},
//     lastUpdatedAt: null,
//     loadingDictionary: {},
//     msgDictionary: {},
//     active: false,
//     loading: false,
//   };
//
//   const slice = createSlice({
//     name: name,
//     initialState,
//     reducers: {
//       setResourceDictionary: (
//         _state: Draft<SubscriptiveResourceList<S>>,
//         { payload: dictionary }: PayloadAction<Dictionary<S>>
//       ) => {
//         const state: SubscriptiveResourceList<S> = current(
//           _state
//         ) as SubscriptiveResourceList<S>;
//         return { ...state, resourceDictionary: dictionary };
//       },
//       onPublish: (
//         _state: Draft<SubscriptiveResourceList<S>>,
//         { payload: resources }: PayloadAction<S[]>
//       ): SubscriptiveResourceList<S> => {
//         const state: SubscriptiveResourceList<S> = current(
//           _state
//         ) as SubscriptiveResourceList<S>;
//
//         const deletedResourceIds: string[] = R.pipe<S[], S[], string[]>(
//           R.filter(deletedFilterFn),
//           R.map(R.prop("id"))
//         )(resources);
//         const updatedResources: S[] = R.filter(
//           R.complement(deletedFilterFn),
//           resources
//         );
//         const newResourceDictionary = R.pipe<
//           S[],
//           Dictionary<S>,
//           Dictionary<S>,
//           Dictionary<S>
//         >(
//           R.indexBy(R.prop("id")),
//           R.merge(state.resourceDictionary),
//           R.omit(deletedResourceIds)
//         )(updatedResources);
//         const lastUpdatedPatch = R.pipe<S[], S[], S>(
//           R.sortBy(R.prop("updatedAt")),
//           R.last
//         )(resources);
//
//         return {
//           ...state,
//           lastUpdatedAt: lastUpdatedPatch?.updatedAt,
//           resourceDictionary: newResourceDictionary,
//         };
//       },
//
//       setLoading: (state, { payload: loading }: PayloadAction<boolean>) => {
//         return {
//           ...state,
//           loading,
//         };
//       },
//       setSubscribed: (
//         state,
//         { payload: subscribed }: PayloadAction<boolean>
//       ) => {
//         return {
//           ...state,
//           subscribed,
//         };
//       },
//       ...reducers,
//     },
//   });
//
//   const { onPublish, setLoading, setSubscribed } = slice.actions;
//   const select = (state: RootState) => state[name];
//
//   const unsubscribe = () => async (
//     dispatch: Dispatch<any>,
//     getState: () => RootState,
//     getSocket: () => Socket
//   ) => {
//     const socket = getSocket();
//     dispatch(setLoading(true));
//     await emitAsync(socket, `${name}:unsubscribe`, null);
//     socket.off(`${name}:publish`);
//     dispatch(setLoading(false));
//     dispatch(setSubscribed(false));
//   };
//
//   const subscribe = () => async (
//     dispatch: Dispatch<any>,
//     getState: () => RootState,
//     getSocket: () => Socket
//   ) => {
//     const socket = getSocket();
//     const { lastUpdatedAt } = select(getState());
//     console.log(`Subscribing to ${name}:publish`);
//
//     dispatch(setLoading(true));
//     socket.on(`${name}:publish`, (data: S[]) => {
//       dispatch(onPublish(data));
//     });
//     const { status } = await emitAsync(socket, `${name}:subscribe`, {
//       ...(lastUpdatedAt && { lastUpdatedAt }),
//     });
//     if (status === "ok") {
//       dispatch(setSubscribed(true));
//     }
//   };
//
//   const reconnect = () => async (
//     dispatch: Dispatch<any>,
//     getState: () => RootState,
//     getSocket: () => Socket
//   ) => {
//     const socket = getSocket();
//     const { subscribed } = select(getState());
//     if (subscribed) {
//       dispatch(setSubscribed(false));
//       // dispatch(setResourceDictionary({}));
//       dispatch(subscribe());
//     }
//   };
//
//   const create = (data: TInCreate) => async (
//     dispatch: Dispatch<any>,
//     getState: () => RootState,
//     getSocket: () => Socket
//   ): Promise<ResponsePayload<S>> => {
//     const socket = getSocket();
//     dispatch(setLoading(true));
//     const response = await emitAsync<ResponsePayload<S>>(
//       socket,
//       `${name}:create`,
//       data
//     );
//     dispatch(setLoading(false));
//     if (response.status !== "ok") {
//     }
//     return response;
//   };
//
//   const update = (data: TInUpdate) => async (
//     dispatch: Dispatch<any>,
//     getState: () => RootState,
//     getSocket: () => Socket
//   ): Promise<ResponsePayload<S>> => {
//     const socket = getSocket();
//     dispatch(setLoading(true));
//     const response = await emitAsync<ResponsePayload<S>>(
//       socket,
//       `${name}:update`,
//       data
//     );
//     dispatch(setLoading(false));
//     return response;
//   };
//
//   const remove = (data: { id: string }) => async (
//     dispatch: Dispatch<any>,
//     getState: () => RootState,
//     getSocket: () => Socket
//   ): Promise<ResponsePayload<S>> => {
//     const socket = getSocket();
//     dispatch(setLoading(true));
//     const response = await emitAsync<ResponsePayload<S>>(
//       socket,
//       `${name}:remove`,
//       data
//     );
//     dispatch(setLoading(false));
//     return response;
//   };
//
//   const reducer = slice.reducer;
//   return {
//     select,
//     reconnect,
//     unsubscribe,
//     reducer,
//     onPublish,
//     subscribe,
//     create,
//     update,
//     remove,
//     selectResourceList,
//     slice,
//     initialState,
//   };
// };
