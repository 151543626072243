import { compareTwoStrings } from 'string-similarity';

/**
 * Returns the parent element of the given node.
 *
 * @param node - The HTML element to get the parent of.
 * @returns - The parent element of the node, or the document body if the node has no parent.
 */
export const getContainer = (node: HTMLElement) => node.parentElement || document.body;

/**
 * Filters options based on a fuzzy search based on input.The function checks if the search input is included in the option label,
 * or if the option label is similar to the search input based on a string similarity comparison by removing whitespace from both.
 *
 * @param input - The search input.
 * @param option - The option to check. It should have a label and a value.
 * @returns - True if the search input is similar to label, false otherwise.
 */
export const fuzzySelectFilter = (input: string, option?: { label: string; value: string }) => {
  const searchString = input.toLowerCase().replaceAll(' ', '');
  const name = (option || { label: '' }).label.toLowerCase().replaceAll(' ', '');
  return searchString.length === 0 || name.includes(searchString) || compareTwoStrings(name, searchString) > 0.8;
};
