import {
  Button,
  Form,
  Input,
  Popconfirm,
  Select,
  Table,
  Tag,
  Typography,
  type FormItemProps,
  type InputProps,
  type SelectProps,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { type ColumnType } from 'antd/es/table';
import type { CoDriver, DailyLog, LogDate } from 'interfaces';
import moment from 'moment-timezone';
import { useCallback, useEffect, useState, type FC, type HTMLAttributes, type Key, type ReactNode } from 'react';
import * as DriverSlice from 'slices/drivers';
import { useDrivers } from 'slices/drivers';
import type { DepsAreEqual } from '../hooks/types';
import { useAppDispatch } from '../store/store';
import { fuzzySelectFilter } from '../utils/html';
import { timezones } from '../utils/timezone';

const logsAreSame = (first: DailyLog, second: DailyLog) => {
  if (first.form.trailers.join(' ') !== second.form.trailers.join(' ')) {
    return false;
  }

  if (first.form.shippingDocuments.join(' ') !== second.form.shippingDocuments.join(' ')) {
    return false;
  }

  if (first.form.signature !== second.form.signature) {
    return false;
  }

  return first.form.coDriver?.id === second.form.coDriver?.id;
};

const logsComparator: DepsAreEqual<DailyLog[]> = (prevLogs, nextLogs) => {
  for (const next of nextLogs) {
    const previous = prevLogs.find((log) => log._id === next._id);
    if (previous === undefined) {
      return false;
    }

    if (!logsAreSame(previous, next)) {
      return false;
    }
  }

  return true;
};

interface EditableCellProps<inputType = 'text' | 'select'> extends HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string | number | (string | number)[];
  extra?: string;
  inputType?: inputType;
  inputProps?: inputType extends 'select' ? SelectProps : InputProps;
  record: DailyLog;
  index: number;
  rules?: FormItemProps['rules'];
  children: ReactNode;
}

const EditableCell: FC<EditableCellProps> = ({
  editing,
  dataIndex,
  extra,
  inputType,
  inputProps,
  rules,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === 'select' ? <Select {...(inputProps as SelectProps)} /> : <Input {...(inputProps as InputProps)} />;

  return (
    <td {...restProps} style={{ verticalAlign: 'top' }}>
      {editing ? (
        <Form.Item name={dataIndex} extra={extra} style={{ margin: 0 }} rules={rules && rules.length > 0 ? rules : []}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export interface ProfileFormData {
  driverId?: string;
  companyId?: string | null;
  from?: string | null;
  to?: string | null;
}

interface OwnProps extends ProfileFormData {
  onClose: () => void;
  onCertify: (driverId: string, signature: string) => Promise<void>;
  actionsDisabled: boolean;
  onCoDriverChanged: () => void;
}

const ProfileForm: FC<OwnProps> = ({
  onClose,
  onCertify,
  actionsDisabled,
  onCoDriverChanged,
  companyId,
  driverId,
  from,
  to,
}) => {
  const appDispatch = useAppDispatch();
  const { drivers } = useDrivers(companyId || null) || {};
  const [form] = useForm<{
    form: { trailers: string[]; shippingDocuments: string[]; signature?: string; coDriver?: string };
  }>();
  const [
    logs,
    setLogs,
  ] = useState<DailyLog[]>();
  const [
    originalLogs,
    setOriginalLogs,
  ] = useState<DailyLog[]>();
  const [
    editingKey,
    setEditingKey,
  ] = useState<string | number>();
  const [
    hasChanges,
    setHasChanges,
  ] = useState(false);
  const [
    isSaving,
    setIsSaving,
  ] = useState(false);
  const [
    signature,
    setSignature,
  ] = useState<string>();

  const fetchDailyLogs = useCallback(
    async (driverId: string, companyId: string, from: string, to: string) => {
      return await appDispatch(DriverSlice.getDailyLogs(driverId, companyId, from, to));
    },
    [appDispatch]
  );

  const reload = useCallback(async () => {
    if (!companyId || !driverId || !from || !to) {
      return;
    }

    setLogs(undefined);
    const reloadedLogs = await fetchDailyLogs(driverId, companyId, from, to);
    setLogs(reloadedLogs);
    setOriginalLogs(reloadedLogs);
  }, [
    fetchDailyLogs,
    driverId,
    companyId,
    from,
    to,
  ]);

  const certify = useCallback(async () => {
    if (!signature || !driverId) {
      return;
    }

    setLogs(undefined);
    await onCertify(driverId, signature);
    await reload();
  }, [
    reload,
    onCertify,
    signature,
    driverId,
  ]);

  useEffect(() => {
    if (!companyId || !driverId || !from || !to) {
      return;
    }
    let isMounted = true;

    fetchDailyLogs(driverId, companyId, from, to).then((data) => {
      if (isMounted) {
        setLogs(data);
        setOriginalLogs(data);
      }
    });

    return () => {
      isMounted = false;
      setHasChanges(false);
      setLogs(undefined);
      setEditingKey(undefined);
    };
  }, [
    fetchDailyLogs,
    driverId,
    companyId,
    from,
    to,
  ]);

  useEffect(() => {
    if (!logs || logs.length === 0) {
      return;
    }

    setSignature(logs.find((log) => log.form.signature !== undefined)?.form?.signature);
  }, [logs]);

  useEffect(() => {
    if (logs === undefined || originalLogs === undefined) {
      return;
    }

    setHasChanges(!logsComparator(originalLogs, logs));

    return () => {
      setHasChanges(false);
    };
  }, [
    logs,
    originalLogs,
  ]);

  const isEditing = (record: DailyLog) => record._id === editingKey;

  const edit = useCallback(
    (record: Partial<DailyLog>) => {
      form.setFieldsValue({
        form: {
          trailers: record.form?.trailers || [],
          shippingDocuments: record.form?.shippingDocuments || [],
          signature: record.form?.signature,
          coDriver: record.form?.coDriver?.id,
        },
      });
      setEditingKey(record._id);
    },
    [form]
  );

  const cancel = useCallback(() => {
    setEditingKey(undefined);
  }, []);

  const save = useCallback(
    async (key: Key) => {
      if (logs === undefined || drivers === undefined) {
        setEditingKey(undefined);
        return;
      }

      try {
        const updatedLog = await form.validateFields();
        const currentIndex = logs.findIndex((log) => log._id === key);
        if (currentIndex === -1) {
          setEditingKey(undefined);
          return;
        }
        const newLogs = [...logs];
        const current = newLogs[currentIndex];
        let coDriver = current.form.coDriver;
        if (updatedLog.form.coDriver === undefined) {
          coDriver = undefined;
        } else if (coDriver?.id !== updatedLog.form.coDriver) {
          const newDriver = drivers.find((driver) => driver._id === updatedLog.form.coDriver);
          coDriver =
            newDriver === undefined
              ? coDriver
              : {
                  id: newDriver._id,
                  firstName: newDriver.firstName,
                  lastName: newDriver.lastName,
                  email: newDriver.email,
                };
        }
        newLogs.splice(currentIndex, 1, {
          ...current,
          form: {
            ...current.form,
            trailers: updatedLog.form.trailers,
            shippingDocuments: updatedLog.form.shippingDocuments,
            signature: updatedLog.form.signature,
            coDriver,
          },
        });
        setLogs(newLogs);
        setEditingKey(undefined);
      } catch (e) {
        // Validation error
        console.error(e);
      }
    },
    [
      form,
      logs,
      drivers,
    ]
  );

  const fillAll = useCallback(
    (key: Key) => {
      if (logs === undefined) {
        return;
      }

      const current = logs.find((log) => log._id === key);
      if (current === undefined) {
        return;
      }

      const newLogs = logs.map((log) => {
        return {
          ...log,
          form: {
            ...log.form,
            trailers: current.form.trailers,
          },
        } as DailyLog;
      });
      setLogs(newLogs);
    },
    [logs]
  );

  const columns: Array<
    ColumnType<DailyLog> &
      Pick<EditableCellProps, 'inputType' | 'inputProps' | 'rules' | 'extra'> & { editable?: boolean }
  > = [
    {
      title: 'Date',
      dataIndex: 'logDate',
      key: 'date',
      render: (date: LogDate) => (
        <span style={{ display: 'block', margin: '4px 0' }}>
          {moment.tz(date.date, 'YYYY/MM/DD', timezones[date.timeZone.id]).format('MMM D, YYYY')} ({date.timeZone.id})
        </span>
      ),
    },
    {
      title: 'Co-driver',
      dataIndex: [
        'form',
        'coDriver',
      ],
      key: 'co-driver',
      editable: true,
      inputType: 'select',
      inputProps: {
        options: drivers
          ?.filter((driver) => driver._id !== driverId)
          ?.map((driver) => ({
            label: `${driver.firstName} ${driver.lastName}`,
            value: driver._id,
          })),
        placeholder: 'Select co-driver',
        allowClear: true,
        showSearch: true,
        filterOption: (inputValue, option) => fuzzySelectFilter(inputValue, option as { label: string; value: string }),
      },
      render: (coDriver?: CoDriver) => (coDriver ? `${coDriver.firstName} ${coDriver.lastName}` : null),
    },
    {
      title: 'Trailers',
      dataIndex: [
        'form',
        'trailers',
      ],
      key: 'trailers',
      editable: true,
      inputType: 'select',
      inputProps: {
        mode: 'tags',
        tokenSeparators: [' '],
      },
      rules: [
        {
          type: 'array',
          defaultField: {
            type: 'string',
            max: 10,
            pattern: new RegExp('^[a-zA-Z0-9]+$'),
            message: 'Only alphanumeric characters are allowed and maximum length must be up to 10 characters.',
          },
        },
      ],
      render: (trailers: string[], record) => (
        <>
          {trailers.map((tag, index) => (
            <Tag key={index} color="geekblue">
              {tag}
            </Tag>
          ))}
          {trailers.length > 0 && (
            <Button type="primary" size="small" className="fill-button" onClick={() => fillAll(record._id)}>
              Fill All
            </Button>
          )}
        </>
      ),
    },
    {
      title: 'Shipping Docs',
      dataIndex: [
        'form',
        'shippingDocuments',
      ],
      key: 'shipping-docs',
      editable: true,
      inputType: 'select',
      inputProps: {
        mode: 'tags',
        tokenSeparators: [' '],
      },
      rules: [
        {
          type: 'array',
          defaultField: {
            type: 'string',
            pattern: new RegExp('^[a-zA-Z0-9]+$'),
            message: 'Only alphanumeric characters are allowed.',
          },
        },
      ],
      render: (shippingDocuments: string[]) => (
        <>
          {shippingDocuments.map((tag, index) => (
            <Tag key={index} color="green">
              {tag}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: 'Signature File Name',
      dataIndex: [
        'form',
        'signature',
      ],
      key: 'signature',
      editable: true,
      extra: 'Find certified log of current driver and copy "Signature File Name" from there',
      render: (signature: string | null, record) => (
        <>
          <span>{signature}</span>
        </>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_: any, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link onClick={() => save(record._id)} style={{ marginRight: 8 }}>
              Update
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <Button type="link" style={{ padding: 0 }}>
                Cancel
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Typography.Link
              disabled={editingKey !== undefined}
              onClick={() => edit(record)}
              style={{ marginRight: 8 }}
            >
              Edit
            </Typography.Link>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((column) => {
    if (!column.editable) {
      return column;
    }

    return {
      ...column,
      onCell: (record: DailyLog) =>
        ({
          editing: isEditing(record),
          extra: column.extra,
          inputType: column.inputType,
          inputProps: column.inputProps,
          dataIndex: column.dataIndex,
          rules: column.rules,
        }) as EditableCellProps,
    } as ColumnType<DailyLog> & { editable?: boolean };
  });

  const onSave = useCallback(async () => {
    if (driverId === undefined || !companyId || logs === undefined || originalLogs === undefined) {
      return;
    }

    if (hasChanges) {
      setIsSaving(true);
      const updatedLogs = logs.filter((log, idx) => !logsAreSame(log, originalLogs[idx]));
      const isCoDriverChanged = updatedLogs.some((updatedLog) => {
        const original = originalLogs.find((originalLog) => originalLog.logDate.date === updatedLog.logDate.date);
        return original?.form?.coDriver?.id !== updatedLog.form.coDriver?.id;
      });
      await appDispatch(DriverSlice.updateDailyLogs(driverId, companyId, updatedLogs));
      setOriginalLogs(logs);
      setIsSaving(false);
      if (isCoDriverChanged) {
        onCoDriverChanged();
      }
    }
  }, [
    driverId,
    companyId,
    logs,
    originalLogs,
    hasChanges,
    appDispatch,
    onCoDriverChanged,
  ]);

  return (
    <Form
      className="profile-form"
      form={form}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          event.preventDefault();
          setEditingKey(undefined);
        } else if (event.key === 'Enter' && editingKey !== undefined) {
          event.preventDefault();
          // noinspection JSIgnoredPromiseFromCall
          save(editingKey);
        }
      }}
    >
      <Table
        rowKey="_id"
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        columns={mergedColumns}
        dataSource={logs || []}
        loading={!logs || isSaving}
        bordered
        rowClassName="editable-row"
        onRow={(record) => ({
          onDoubleClick: () => edit(record),
        })}
        scroll={{ y: 'calc(100% - 52px)' }} // 100% - header row height
        pagination={false}
        size="small"
        footer={() => (
          <div style={{ textAlign: 'right', padding: '10px 0' }}>
            <Popconfirm
              overlayClassName="popover-without-padding"
              title={
                <>
                  <strong style={{ display: 'block', marginBottom: '15px' }}>
                    Warning! Existing Certifications and Signatures may be replaced.
                  </strong>
                  <label>
                    Enter signature filename:
                    <Input
                      id="profileSignature"
                      placeholder="Signature"
                      onChange={(event) => setSignature(event.target.value.trim())}
                      defaultValue={signature}
                      style={{ marginTop: '10px' }}
                    />
                  </label>
                </>
              }
              icon={null}
              okButtonProps={{ disabled: signature === undefined || signature === '' }}
              onConfirm={() => {
                // noinspection JSIgnoredPromiseFromCall
                certify();
              }}
            >
              <Button type="default" disabled={actionsDisabled || logs?.length === 0} style={{ marginRight: '10px' }}>
                Certify
              </Button>
            </Popconfirm>
            <Button type="default" disabled={isSaving} onClick={onClose} style={{ marginRight: '10px' }}>
              Close
            </Button>
            <Button type="primary" disabled={!hasChanges || !!editingKey || actionsDisabled} onClick={onSave}>
              Save
            </Button>
          </div>
        )}
      />
    </Form>
  );
};

export default ProfileForm;
