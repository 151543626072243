import { Modal, Radio, RadioChangeEvent, Space } from 'antd';
import React, { useEffect, useState } from 'react';

type UserSelectionModalProps = {
  isVisible: boolean;
  onConfirm: (selectedUserId?: string) => void;
  onCancel: () => void;
  availableDrivers: {
    id: string;
    fullName: string;
  }[];
};
const UserSelectionModal: React.FC<UserSelectionModalProps> = ({
  isVisible,
  onConfirm,
  onCancel,
  availableDrivers,
}) => {
  const [
    selectedUserId,
    setSelectedUser,
  ] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (availableDrivers.length > 0) {
      setSelectedUser(availableDrivers[0].id);
    }
  }, [availableDrivers]);

  const onChangeSelectedUser = (e: RadioChangeEvent) => {
    setSelectedUser(e.target.value);
  };

  return (
    <Modal title="Select driver" visible={isVisible} onOk={() => onConfirm(selectedUserId)} onCancel={onCancel}>
      <Radio.Group onChange={onChangeSelectedUser} value={selectedUserId} defaultValue={selectedUserId}>
        <Space direction="vertical">
          {(availableDrivers || []).map((user) => (
            <Radio key={user.id} value={user.id}>
              {user.fullName}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </Modal>
  );
};

export default UserSelectionModal;
