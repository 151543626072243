import {
  AlertOutlined,
  ArrowsAltOutlined,
  CopyOutlined,
  DownOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  ShrinkOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Drawer,
  Dropdown,
  InputNumber,
  Layout,
  List as AntList,
  Menu,
  message,
  Modal,
  ModalFuncProps,
  Popconfirm,
  Progress,
  Radio,
  Row,
  Space,
  Spin,
  Switch,
  Tag,
  Tooltip,
} from 'antd';
import ConditionalTooltip from 'components/ConditionalTooltip';
import SelectStep from 'components/SelectStep';
import { useAuthConnectionEffect } from 'hooks/useAuthConnectionEffect';
import {
  AlertPayload,
  DotPayload,
  DriverStatus,
  LogEvent,
  ManualPatchPayload,
  ManualPatchType,
  PatchAvailableHourSlot,
  PatchLogEventPayload,
  SubscriptionLockPayload,
} from 'interfaces';
import moment from 'moment-timezone';
import * as R from 'ramda';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AutoSizer, List } from 'react-virtualized';
import * as AlertsSlice from 'slices/alerts';
import { useAlerts } from 'slices/alerts';
import * as AuditSlice from 'slices/audits';
import { useAudits } from 'slices/audits';
import * as DotSlice from 'slices/dots';
import { useDots } from 'slices/dots';
import * as DriverSlice from 'slices/drivers';
import { useDrivers } from 'slices/drivers';
import * as ManualPatchSlice from 'slices/manualPatch';
import {
  create,
  RemoveManualPatchDriverPayload,
  UpdateManualPatchDriverPayload,
  UpdateManualPatchPayload,
  useManualPatch,
} from 'slices/manualPatch';
import { useMyAccount } from 'slices/myAccount';
import * as PatchLogEventsSlice from 'slices/patchLogEvents';
import { usePatchLogEvents } from 'slices/patchLogEvents';
import * as SubscriptionLockSlice from 'slices/subscriptionLocks';
import { useSubscriptionLocks } from 'slices/subscriptionLocks';
import { useAppDispatch } from 'store/store';
import { getContainer } from 'utils/html';
import { getLogEvent, isDownloadedDOTEvent } from 'utils/patch-log';
import { timezones } from 'utils/timezone';
import { v4 } from 'uuid';
import CopyToClipboard from '../../components/CopyToClipboard';
import DriverLogsChart, { ChartEvent, DrivingEventType } from '../../components/DriverLogsChart';
import DriverStatusChart from '../../components/DriverStatusChart';
import EventUpdateForm from '../../components/EventUpdateForm';
import OdometerOffsetForm from '../../components/OdometerOffsetForm';
import ProfileForm, { ProfileFormData } from '../../components/ProfileForm';
import UserSelectionModal from '../../components/UserSelectionModal';
import { useSessionStorage } from '../../hooks/use-session-storage';
import useSyncEffect from '../../hooks/use-sync-effect';
import useCustomCompareMemo from '../../hooks/useCustomCompareMemo';
import * as CompaniesSlice from '../../slices/companies';
import { useCompanies } from '../../slices/companies';
import { SPEEDING_SPEED_DEFAULT_THRESHOLD, SPEEDING_SPEED_STATE_THRESHOLD } from '../../utils/constants';

const { confirm } = Modal;
const { Panel } = Collapse;

export type ExtStatus = 'init' | 'uploading' | 'finished';
export const eventLabels: Record<string, string> = {
  DS_OFF: 'Off duty',
  LOG_NORMAL_PRECISION: 'Intermediate w/ CLP',
  DR_LOGIN: 'Login',
  DR_LOGOUT: 'Logout',
  DS_ON: 'On duty',
  DS_D: 'Driving',
  DS_SB: 'Sleeper',
  ENG_DOWN_NORMAL: 'Engine Shut-down w/ CLP',
  ENG_UP_NORMAL: 'Engine Power-up w/ CLP',
  DR_IND_YM: 'Yard Moves',
  DR_IND_PC: 'Personal Use',
  DR_CERT_1: 'Certification (1)',
  DR_CERT_2: 'Certification (2)',
  DR_CERT_3: 'Certification (3)',
  DR_CERT_4: 'Certification (4)',
  DR_CERT_5: 'Certification (5)',
  DR_CERT_6: 'Certification (6)',
  DR_CERT_7: 'Certification (7)',
  DR_CERT_8: 'Certification (8)',
  DR_CERT_9: 'Certification (9)',
};

const driverEventColors = [
  '#06437c',
  '#fd8270',
];

export const diagnosticEventLabels: Record<string, string> = {
  D_POWER: 'Power data diagnostic',
  D_ENGINE_SYNC: 'Engine synchronization data diagnostic',
  D_MISSING_REQ_DATA: 'Missing required data elements data diagnostic',
  D_DATA_TRANSFER: 'Data transfer data diagnostic',
  D_UNIDENTIFIED_DRIVING_RECORDS: 'Unidentified driving records data diagnostic',
  D_OTHER: 'Other ELD identified diagnostic',
};

const getEventLabel = (event: LogEvent) => {
  const type = event.eventCode.id;

  if ((type === 'DIAG_LOGGED' || type === 'DIAG_CLEARED') && event.fc?.id !== undefined) {
    const label = diagnosticEventLabels[event.fc.id];

    return type === 'DIAG_CLEARED' ? `${label} (cleared)` : label;
  }

  if (type.startsWith('DR_CERT')) {
    const date = moment(event.certifiedRecordLogDate?.date, 'YYYY/MM/DD').format('M/DD/YYYY');
    return `${eventLabels[type]} ${date}`;
  }

  return eventLabels[type] || type;
};

export const statusLabels: Record<string, string> = {
  ACTIVE: 'Active',
  INACTIVE_CHANGED: 'Inactive',
  INACTIVE_CHANGE_REQUESTED: 'Inactive',
  INACTIVE_CHANGE_REJECTED: 'Inactive',
};

export const originLabels: Record<string, string> = {
  ELD: 'Auto',
  DRIVER: 'Driver',
  OTHER_USER: 'Other User',
  UNIDENTIFIED_DRIVER: 'Unidentified Driver',
};

export const getEventStatus = (patchLogEvent: PatchLogEventPayload) => {
  if (patchLogEvent.failedAt) {
    return 'error';
  } else if (patchLogEvent.committedAt) {
    return 'done';
  } else if (!patchLogEvent.selected) {
    return 'ignored';
  } else {
    return 'pending';
  }
};

const convertMsToTime = (milliseconds: number): string => {
  const seconds = moment.duration(milliseconds).seconds().toString().padStart(2, '0');
  const minutes = moment.duration(milliseconds).minutes().toString().padStart(2, '0');
  const hours = Math.trunc(moment.duration(milliseconds).asHours());

  return `${hours}:${minutes}:${seconds}`;
};

const chartEventsAreEqual = (prevEvents: PatchLogEventPayload[], nextEvents: PatchLogEventPayload[]) => {
  return (
    prevEvents.length === nextEvents.length &&
    prevEvents.every((prevEvent, index) => {
      const nextEvent = nextEvents?.[index];
      return (
        nextEvent !== undefined &&
        prevEvent.originData._id === nextEvent.originData._id &&
        prevEvent.originData.eventCode.id === nextEvent.originData.eventCode.id &&
        prevEvent.originData.eventTime.timestamp === nextEvent.originData.eventTime.timestamp &&
        prevEvent.originData.eventTime.logDate.timeZone.id === nextEvent.originData.eventTime.logDate.timeZone.id &&
        prevEvent.duration === nextEvent.duration &&
        prevEvent.driverId === nextEvent.driverId
      );
    })
  );
};

let focusTimeout: number | undefined;

const ManualPatchDetails = () => {
  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();
  console.time('RENDER');

  let warningModal: {
    destroy: () => void;
    update: (configUpdate: ModalFuncProps | ((prevConfig: ModalFuncProps) => ModalFuncProps)) => void;
  };
  useEffect(() => {
    let secondsToClose = 20;
    const message = process.env.REACT_APP_WARNING_MESSAGE;
    if (warningModal || typeof message !== 'string' || message.length === 0) {
      return;
    }

    warningModal = Modal.warning({
      title: message,
      content: `This modal will be closed after ${secondsToClose} second.`,
      closable: false,
      okButtonProps: {
        style: {
          display: 'none',
        },
      },
    });

    const timer = setInterval(() => {
      secondsToClose -= 1;
      warningModal.update({
        content: `This modal will be closed after ${secondsToClose} second.`,
      });
      if (secondsToClose === 0) {
        clearInterval(timer);
        warningModal.destroy();
      }
    }, 1000);
  }, []);

  let { manualPatchId } = useParams<{ manualPatchId: string }>();

  const { patch, patchSubscribed, patchLoading } = useManualPatch();

  const { auditsById } = useAudits();
  const { alertsById } = useAlerts();
  const { dotsById } = useDots();
  const { subscriptionLocksById } = useSubscriptionLocks();

  const { patchLogEvents, patchLogEventsLoading, patchLogEventsSubscribed } = usePatchLogEvents(manualPatchId);

  const { driversById } = useDrivers(patch?.companyId || null) || {};

  const { companies, companiesSubscribed, companiesLoading } = useCompanies();

  const { myAccount } = useMyAccount();

  const role = myAccount?.role;

  const [
    patchType,
    setPatchType,
  ] = useState<ManualPatchType>(role === 'shifter_hr' ? 'hours' : 'days');

  const history = useHistory();
  const [
    visible,
    setVisible,
  ] = useState<boolean>(false);
  const [
    enableEngineHoursValidation,
    setEnableEngineHoursValidation,
  ] = useSessionStorage('enable-engine-hours-validation', true);
  const onSwitchEngineHoursValidation = (checked: boolean) => {
    setEnableEngineHoursValidation(checked);
  };

  const numberOfErrors = useMemo(() => {
    return patchLogEvents?.filter(({ failedAt }) => failedAt).length || 0;
  }, [patchLogEvents]);
  const numberOfSuccessful = useMemo(() => {
    return patchLogEvents?.filter(({ committedAt }) => committedAt).length || 0;
  }, [patchLogEvents]);
  const numberOfRolledBack = useMemo(() => {
    return patchLogEvents?.filter(({ rollbackAt }) => rollbackAt).length || 0;
  }, [patchLogEvents]);

  const totalNumber = patchLogEvents?.length || 0;
  const [
    shiftAmount,
    setShiftAmount,
  ] = useState(1);
  const totalSelected = useMemo(
    () => (!patchLogEvents ? 0 : patchLogEvents.filter((patchLogEvent) => patchLogEvent.selected).length),
    [
      patchLogEvents,
    ]
  );

  const selectedPatchLogEvents = useMemo(
    () => (patchLogEvents || []).filter((patchLogEvent) => patchLogEvent.selected),
    [patchLogEvents]
  );

  const certificationEvents = useMemo(
    () =>
      (patchLogEvents || []).filter((patchLogEvent) => patchLogEvent.originData.eventCode.id.startsWith('DR_CERT_')),
    [patchLogEvents]
  );

  const onlyCertificationEventsSelected = useMemo(
    () =>
      certificationEvents.every((patchLogEvent) => patchLogEvent.selected) &&
      certificationEvents.length === selectedPatchLogEvents.length,
    [certificationEvents]
  );

  const areDownloadedDOTEventsSelected = selectedPatchLogEvents.some((patchLogEvent) =>
    isDownloadedDOTEvent(getLogEvent(patchLogEvent))
  );

  const sortedEvents = patchLogEvents || [];
  const list = useRef<List>(null);
  let [
    eventsWithErrorOrWarnings,
    currentEventWithErrorIdx,
  ] = useMemo(() => {
    const eventsWIthErrorsOrWarnings = (sortedEvents || []).reduce<number[]>((acc, event, idx) => {
      if (!!event.error || !!event.warning) {
        acc.push(idx);
      }

      return acc;
    }, []);

    return [
      eventsWIthErrorsOrWarnings,
      eventsWIthErrorsOrWarnings.length - 1,
    ];
  }, [sortedEvents]);

  const { hasErrorOrWarning, numberOfUnidentifiedEvents, numberOfInactiveEvents } = useMemo(() => {
    return {
      hasErrorOrWarning: sortedEvents.filter((event) => event.error || event.warning).length > 1,
      numberOfUnidentifiedEvents: sortedEvents.filter(
        (event) => event.originData.recordOrigin?.id === 'UNIDENTIFIED_DRIVER'
      ).length,
      numberOfInactiveEvents: sortedEvents.filter((event) => event.originData.recordStatus?.id.startsWith('INACTIVE_'))
        .length,
    };
  }, [sortedEvents]);

  const lastFinishedIndex = useMemo(
    () =>
      Math.min(
        R.findLastIndex((patchLogEvent) => !!patchLogEvent.committedAt, sortedEvents || []) + 5,
        sortedEvents.length - 1
      ),
    [sortedEvents]
  );

  const chartEvents = useCustomCompareMemo(
    () => {
      if (sortedEvents.length === 0 || patch?.to === undefined) {
        return [];
      }

      const filteredEvents = sortedEvents.filter((event) =>
        [
          'DS_D',
          'DS_ON',
          'DS_SB',
          'DS_OFF',
          'DR_IND_YM',
          'DR_IND_PC',
        ].includes(event.originData.eventCode.id)
      );

      const events = filteredEvents.map((event) => {
        const patchDriverIndex = patch.drivers.findIndex((driver) => driver.driverId === event.driverId);
        return {
          id: event.originData._id,
          type: event.originData.eventCode.id as DrivingEventType,
          timestamp: event.originData.eventTime.timestamp,
          timezone: timezones[event.originData.eventTime.logDate.timeZone.id] || 'America/Los_Angeles',
          duration: event.duration!,
          color: driverEventColors[patchDriverIndex === -1 ? 0 : patchDriverIndex],
        } as ChartEvent;
      });

      if (patch && events.length > 0) {
        const lastEvent = events[events.length - 1];
        let patchEndTimestamp: number | null = null;

        if (patch.drivers.length > 1) {
          const drivers = patch.drivers;
          const patchTo = Math.max(
            ...drivers.map((driver) => {
              const driverEvents = filteredEvents.filter((event) => event.driverId === driver.driverId);
              const timezone =
                timezones[driverEvents[driverEvents.length - 1]?.originData.eventTime.logDate.timeZone.id] ||
                'America/Los_Angeles';

              return driver.to !== null
                ? moment.tz(driver.to.slice(0, 19), timezone).valueOf()
                : moment().tz(timezone).valueOf();
            })
          );

          patchEndTimestamp = Math.min(patchTo, moment().tz(lastEvent.timezone).valueOf());
        } else if (patch.to) {
          patchEndTimestamp = Math.min(
            moment.tz(patch.to.slice(0, 19), lastEvent.timezone).add(1, 'd').valueOf(),
            moment().tz(lastEvent.timezone).valueOf()
          );
        }

        if (patchEndTimestamp) {
          lastEvent.duration = patchEndTimestamp - lastEvent.timestamp;
          events.push({
            id: 'unknown',
            type: lastEvent.type,
            timestamp: patchEndTimestamp,
            timezone: lastEvent.timezone,
            duration: 0,
            color: lastEvent.color,
          });
        }
      }

      return events;
    },
    [
      patch,
      sortedEvents,
    ] as [ManualPatchPayload | undefined, PatchLogEventPayload[]],
    (
      [
        prevPatch,
        prevEvents,
      ],
      [
        nextPatch,
        nextEvents,
      ]
    ) => prevPatch === nextPatch && chartEventsAreEqual(prevEvents, nextEvents)
  );

  const patchDrivers = useMemo(() => patch?.drivers || [], [JSON.stringify(patch?.drivers || [])]);

  const testDrivers = useMemo(() => {
    if (driversById !== undefined && Object.keys(driversById).length > 0 && patchDrivers.length > 0) {
      const patchDriverIds = patchDrivers.map((driver) => driver.driverId);
      const users = Object.values(driversById).filter((driver) => !patchDriverIds.includes(driver._id));
      return users.filter((driver) => {
        const fullNameLowerCase = `${driver.firstName} ${driver.lastName}`.toLowerCase();
        return (
          fullNameLowerCase.includes('test') &&
          fullNameLowerCase.includes('driver') &&
          !fullNameLowerCase.includes('audit')
        );
      });
    }
  }, [
    driversById,
    patchDrivers,
  ]);

  const [
    driverStatus,
    setDriverStatus,
  ] = useState<DriverStatus>();

  const [
    coDriverStatus,
    setCoDriverStatus,
  ] = useState<DriverStatus>();

  const [
    isStatusChartHovered,
    setIsStatusChartHovered,
  ] = useState(false);

  const [
    areDriverStatusesLoading,
    setDriverStatusesLoading,
  ] = useState(false);

  const fetchDriverStatuses = useCallback(
    (fresh?: boolean) => {
      if (!patch?.companyId || patchDrivers.length === 0) {
        setDriverStatus(undefined);
        setCoDriverStatus(undefined);
        return;
      }

      const fetchDriverStatus = async () => {
        setDriverStatusesLoading(true);
        const status = await appDispatch(
          DriverSlice.getDriverStatus(patchDrivers[0].driverId, patch.companyId!, fresh)
        );
        setDriverStatus(status);
        setDriverStatusesLoading(false);
      };

      const fetchCoDriverStatus = async () => {
        setDriverStatusesLoading(true);
        const status = await appDispatch(
          DriverSlice.getDriverStatus(patchDrivers[1].driverId, patch.companyId!, fresh)
        );
        setCoDriverStatus(status);
        setDriverStatusesLoading(false);
      };

      // noinspection JSIgnoredPromiseFromCall
      fetchDriverStatus();
      if (patchDrivers.length > 1) {
        // noinspection JSIgnoredPromiseFromCall
        fetchCoDriverStatus();
      } else {
        setCoDriverStatus(undefined);
      }
    },
    [
      appDispatch,
      patch?.companyId,
      patchDrivers,
    ]
  );

  useEffect(() => {
    fetchDriverStatuses();
  }, [fetchDriverStatuses]);

  const [
    isDrawerOpen,
    setIsDrawerOpen,
  ] = useState(false);

  const [
    focusedEvent,
    setFocusedEvent,
  ] = useState<string>();

  useEffect(() => {
    if (!focusedEvent || !list.current) {
      return;
    }

    list.current.forceUpdateGrid();
  }, [focusedEvent]);

  const [
    patchAvailableHours,
    setPatchAvailableHours,
  ] = useState<PatchAvailableHourSlot[]>();

  const [
    reportDriverId,
    setReportDriverId,
  ] = useState<string>();

  const availableHourEvents = useCustomCompareMemo(
    () => {
      if (!sortedEvents || sortedEvents.length === 0) {
        return [];
      }

      return sortedEvents.map((event) => event.id);
    },
    [sortedEvents],
    ([prevEvents], [nextEvents]) =>
      prevEvents?.length === nextEvents?.length &&
      prevEvents.every((prevEvent, index) => {
        const nextEvent = nextEvents?.[index];
        return (
          nextEvent !== undefined &&
          prevEvent.originData.eventCode.id === nextEvent.originData.eventCode.id &&
          prevEvent.duration === nextEvent.duration
        );
      })
  );

  const downloadReport = async () => {
    if (
      patch?.reportIsGenerated !== true ||
      !patch?.companyName ||
      myAccount === undefined ||
      reportDriverId === undefined ||
      patch.drivers.length === 0
    ) {
      return;
    }

    const reportDriver = patch.drivers.find((driver) => driver.driverId === reportDriverId);
    if (!reportDriver) {
      return;
    }

    const buffer = await appDispatch(ManualPatchSlice.downloadReport(patch.id, reportDriverId));
    const pdfBlob = new Blob([buffer], { type: 'application/pdf' });
    const url = URL.createObjectURL(pdfBlob);
    const from = moment.utc().subtract(30, 'days').startOf('day');
    const to = moment.utc().startOf('day');
    // Following method of force-downloading is not reliable
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `${reportDriver.driverName} - ${patch.companyName} ${from.format('MMM DD, YYYY')}-${to.format('MMM DD, YYYY')} ${
        myAccount.username
      }.pdf`
    );
    link.style.visibility = 'hidden';
    // Append to html link element page
    document.body.appendChild(link);
    // Start download
    link.click();
    // Clean up and remove the link
    link.parentNode?.removeChild(link);
    setReportDriverId(undefined);
  };

  useSyncEffect(
    ([prevReportIsGenerated]) => {
      if (prevReportIsGenerated === false && patch?.reportIsGenerated === true) {
        // noinspection ES6MissingAwait
        // noinspection JSIgnoredPromiseFromCall
        downloadReport();
      }
    },
    [patch?.reportIsGenerated]
  );

  useEffect(() => {
    if (!patch?.id || availableHourEvents.length === 0) {
      return;
    }

    const fetchAvailableHours = async () => {
      const availableHours = await appDispatch(ManualPatchSlice.getAvailableHours(patch.id));
      setPatchAvailableHours(availableHours);
    };

    // noinspection JSIgnoredPromiseFromCall
    fetchAvailableHours();
  }, [
    appDispatch,
    patch?.id,
    availableHourEvents,
  ]);

  const isAdminOrShifter = useMemo(() => {
    return (
      role !== undefined &&
      [
        'admin',
        'manager',
        'shifter_hr',
        'shifter_day',
        'shifter_hr_day',
        'unrestricted_shifter_hr_day',
      ].includes(role)
    );
  }, [role]);

  const patchStatus = patch?.status;

  const patchIsShifted = useMemo(() => {
    return (
      patchStatus !== undefined &&
      [
        'shift_requested',
        'shift_started',
        'shift_stopped',
      ].includes(patchStatus)
    );
  }, [patchStatus]);

  const patchIsBeingFetched = useMemo(() => {
    return (
      patchStatus !== undefined &&
      [
        'fetch_requested',
        'fetch_started',
      ].includes(patchStatus)
    );
  }, [patchStatus]);

  const patchIsFinishedOrStopped = useMemo(() => {
    return (
      patchStatus !== undefined &&
      [
        'fetch_finished',
        'shift_stopped',
      ].includes(patchStatus)
    );
  }, [patchStatus]);

  const engineEventPresent = useMemo(() => {
    return (
      !!patchLogEvents &&
      !!patchLogEvents.length &&
      patchLogEvents.some(
        (event) =>
          event.originData.eventCode.id === 'ENG_DOWN_NORMAL' || event.originData.eventCode.id === 'ENG_UP_NORMAL'
      )
    );
  }, [patchLogEvents]);

  let setChartEventIdToHighlight: Dispatch<SetStateAction<string | null>> | null = null;
  const hoveredEventId = useRef<string | undefined>(undefined);
  const [
    isGeneratingIntermediateEvents,
    setIsGeneratingIntermediateEvents,
  ] = useState<boolean>(false);
  const [
    logsToArchive,
    setLogsToArchive,
  ] = useState<string[]>([]);
  const [
    isUserSelectionModalVisible,
    setUserSelectionModalVisible,
  ] = useState(false);
  const [
    isCoDriverIncludedInReassign,
    setCoDriverIncludedInReassign,
  ] = useState(false);

  const [
    isEventUpdateModalVisible,
    setEventUpdateModalVisible,
  ] = useState(false);
  const [
    eventToUpdate,
    setEventToUpdate,
  ] = useState<(LogEvent & { odometerOffset?: number }) | undefined>();

  const [
    isOdometerOffsetModalVisible,
    setOdometerOffsetModalVisible,
  ] = useState(false);

  useAuthConnectionEffect(() => {
    dispatch(AuditSlice.subscribe());
    dispatch(DotSlice.subscribe());
    dispatch(SubscriptionLockSlice.subscribe());
    dispatch(AlertsSlice.subscribe());
    return () => {
      dispatch(AuditSlice.unsubscribe());
      dispatch(DotSlice.unsubscribe());
      dispatch(SubscriptionLockSlice.unsubscribe());
      dispatch(AlertsSlice.unsubscribe());
    };
  }, []);

  const conflictingAlerts: AlertPayload[] = Object.values(alertsById).filter((alert) => {
    if (alert.companyId !== patch?.companyId) {
      return false;
    }

    if (alert.driverId && !patchDrivers.map((driver) => driver.driverId).includes(alert.driverId)) {
      return false;
    }

    const alertTo = alert.to ? moment.utc(alert.to).add(1, 'days').startOf('day') : null;
    const now = moment().startOf('day');

    return alertTo ? now.isBefore(alertTo) : true;
  });
  const alertsHash =
    conflictingAlerts.length > 0
      ? conflictingAlerts
          .map((alert) => alert.id)
          .sort()
          .join('.')
      : null;

  const [
    showAlert,
    setShowAlert,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (alertsHash !== null) {
      setShowAlert(true);
    }
  }, [alertsHash]);

  useAuthConnectionEffect(() => {
    if (manualPatchId) {
      dispatch(ManualPatchSlice.subscribe({ id: manualPatchId }));
      return () => {
        dispatch(ManualPatchSlice.unsubscribe({ id: manualPatchId }));
      };
    }
  }, [manualPatchId]);

  useAuthConnectionEffect((): (() => any) | undefined => {
    if (patch?.companyId) {
      const companyId = patch?.companyId;
      dispatch(DriverSlice.subscribe(companyId));
      return () => dispatch(DriverSlice.unsubscribe(companyId));
    }
  }, [
    patch?.companyId,
  ]);

  useAuthConnectionEffect(() => {
    dispatch(CompaniesSlice.subscribe());
    return () => {
      dispatch(CompaniesSlice.unsubscribe());
    };
  }, []);

  const patchTimezone = patch?.companyId
    ? companies
        ?.find((company) => company._id === patch.companyId)
        ?.terminals?.find(
          (terminal) => terminal.id === driversById?.[patchDrivers?.[0]?.driverId]?.driverInfo?.homeTerminal?.id
        )?.timeZone?.id || null
    : null;

  useAuthConnectionEffect(() => {
    dispatch(PatchLogEventsSlice.subscribe(manualPatchId));
    return () => {
      manualPatchId && dispatch(PatchLogEventsSlice.unsubscribe(manualPatchId));
    };
  }, [manualPatchId]);

  const manualPatchType = patch?.type;

  useEffect(() => {
    if (manualPatchType) setPatchType(manualPatchType);
  }, [manualPatchType]);

  const patchShiftAmount = patch?.shiftAmount;

  useEffect(() => {
    if (patchShiftAmount != null && patchShiftAmount !== undefined) {
      setShiftAmount(patchShiftAmount);
    }
  }, [patchShiftAmount]);

  useEffect(() => {
    if (role) {
      if (role === 'shifter_day') {
        setPatchType('days');
      }
      if (role === 'shifter_hr') {
        setPatchType('hours');
      }
    }
  }, [
    role,
    patchType,
  ]);

  const [
    lastSelectedAction,
    setLastSelectedAction,
  ] = useState<undefined | { id: string; state: boolean }>();

  const [
    isProfileFormVisible,
    setIsProfileFormVisible,
  ] = useState(false);
  const [
    isProfileFormFullscreen,
    setIsProfileFormFullScreen,
  ] = useState(false);
  const [
    profileFormData,
    setProfileFormData,
  ] = useState<ProfileFormData>();

  const [
    hasIncorrectCoDriver,
    setHasIncorrectCoDriver,
  ] = useState(false);

  const [
    profileFormChanged,
    setProfileFormChanged,
  ] = useState(true);

  useEffect(() => {
    if (!profileFormChanged) {
      return;
    }

    if (!patch?.companyId || patchDrivers.length === 0) {
      setHasIncorrectCoDriver(false);
    } else {
      // noinspection JSIgnoredPromiseFromCall
      checkIncorrectCoDrivers();
    }

    async function checkIncorrectCoDrivers() {
      if (!patch?.companyId) {
        setHasIncorrectCoDriver(false);
        return;
      }

      if (patchDrivers.length === 1 && patchDrivers[0].from !== null && patchDrivers[0].to !== null) {
        const driverLogs = await appDispatch(
          DriverSlice.getDailyLogs(patchDrivers[0].driverId, patch.companyId, patchDrivers[0].from, patchDrivers[0].to)
        );

        if (driverLogs.some((log) => log.form.coDriver !== undefined)) {
          setHasIncorrectCoDriver(true);
        } else {
          setHasIncorrectCoDriver(false);
        }
        setProfileFormChanged(false);
        return;
      }

      if (
        patchDrivers[0].from === null ||
        patchDrivers[0].to === null ||
        patchDrivers[1].from === null ||
        patchDrivers[1].to === null
      ) {
        setHasIncorrectCoDriver(false);
        setProfileFormChanged(false);
        return;
      }

      const firstDriverLogs = await appDispatch(
        DriverSlice.getDailyLogs(patchDrivers[0].driverId, patch.companyId, patchDrivers[0].from, patchDrivers[0].to)
      );

      const secondDriverLogs = await appDispatch(
        DriverSlice.getDailyLogs(patchDrivers[1].driverId, patch.companyId, patchDrivers[1].from, patchDrivers[1].to)
      );

      // Find intersection of dates
      const patchPeriods = patchDrivers.map((driver) => ({
        from: moment.utc(driver.from),
        to: moment.utc(driver.to),
      }));
      const patchMin = patchPeriods[0].from < patchPeriods[1].from ? patchPeriods[0] : patchPeriods[1];
      const patchMax = patchMin === patchPeriods[0] ? patchPeriods[1] : patchPeriods[0];

      if (patchMin.to < patchMax.from) {
        // We don't have intersection
        // non-intersected periods must not have any co-driver
        if (
          firstDriverLogs.some((driverLog) => driverLog.form.coDriver !== undefined) ||
          secondDriverLogs.some((driverLog) => driverLog.form.coDriver !== undefined)
        ) {
          setProfileFormChanged(false);
          setHasIncorrectCoDriver(true);
          return;
        }

        setProfileFormChanged(false);
        setHasIncorrectCoDriver(false);
        return;
      }

      const fullRange = [
        ...Array((patchMin.to < patchMax.to ? patchMax.to : patchMin.to).diff(patchMin.from, 'days') + 1),
      ].map((_, i) => patchMin.from.clone().add(i, 'days').format('YYYY/MM/DD'));

      const firstDriverLogsMap = new Map(
        firstDriverLogs.map((driverLog) => [
          driverLog.logDate.date,
          driverLog,
        ])
      );
      const secondDriverLogsMap = new Map(
        secondDriverLogs.map((driverLog) => [
          driverLog.logDate.date,
          driverLog,
        ])
      );

      let hasCoDriverSetAtAll = false;

      for (let date of fullRange) {
        if (firstDriverLogsMap.has(date) && secondDriverLogsMap.has(date)) {
          // intersecting period
          const firstDriverCoDriver = firstDriverLogsMap.get(date)?.form.coDriver;
          const secondDriverCoDriver = secondDriverLogsMap.get(date)?.form.coDriver;

          if (firstDriverCoDriver?.id === undefined && secondDriverCoDriver?.id === undefined) {
            continue;
          }

          if (
            (firstDriverCoDriver === undefined && secondDriverCoDriver !== undefined) ||
            (firstDriverCoDriver !== undefined && secondDriverCoDriver === undefined)
          ) {
            setProfileFormChanged(false);
            setHasIncorrectCoDriver(true);
            return;
          }

          hasCoDriverSetAtAll = true;

          if (
            firstDriverCoDriver?.id !== patchDrivers[1].driverId ||
            secondDriverCoDriver?.id !== patchDrivers[0].driverId
          ) {
            setProfileFormChanged(false);
            setHasIncorrectCoDriver(true);
            return;
          }

          continue;
        }

        if (
          firstDriverLogsMap.has(date) &&
          !secondDriverLogsMap.has(date) &&
          firstDriverLogsMap.get(date)?.form.coDriver !== undefined
        ) {
          setProfileFormChanged(false);
          setHasIncorrectCoDriver(true);
          return;
        }

        if (
          secondDriverLogsMap.has(date) &&
          !firstDriverLogsMap.has(date) &&
          secondDriverLogsMap.get(date)?.form.coDriver !== undefined
        ) {
          setProfileFormChanged(false);
          setHasIncorrectCoDriver(true);
          return;
        }
      }

      if (!hasCoDriverSetAtAll) {
        setProfileFormChanged(false);
        setHasIncorrectCoDriver(true);
        return;
      }

      setProfileFormChanged(false);
      setHasIncorrectCoDriver(false);
    }
  }, [
    profileFormChanged,
    appDispatch,
    patch?.companyId,
    patchDrivers,
  ]);

  const downloadedAlertPeriod = useMemo((): undefined | null | [string, string] => {
    return (
      patchLogEvents &&
      R.pipe<PatchLogEventPayload[], LogEvent[], LogEvent[], null | [string, string]>(
        R.map((patchLog) => {
          return patchLog.originData;
        }),
        R.filter((logEvent: LogEvent) => {
          return isDownloadedDOTEvent(logEvent);
        }),
        (logs) => {
          const count = logs.length;
          if (count) {
            const timestamps = logs.map((log) => log.eventTime.timestamp);
            const from = moment
              .tz(Math.min(...timestamps), timezones[logs[0].eventTime.logDate.timeZone.id] || 'America/Los_Angeles')
              .format('MMM DD, hh:mm:ss a');
            const to = moment
              .tz(Math.max(...timestamps), timezones[logs[0].eventTime.logDate.timeZone.id] || 'America/Los_Angeles')
              .format('MMM DD, hh:mm:ss a');
            return [
              from,
              to,
            ];
          } else {
            return null;
          }
        }
      )(patchLogEvents)
    );
  }, [patchLogEvents]);

  const downloadedAlert = downloadedAlertPeriod && [
    `⚠️ Warning ⚠️`,
    `The driver has downloaded DOT inspection ${downloadedAlertPeriod[0]} - ${downloadedAlertPeriod[1]}`,
  ];

  const skipDownloadedMessage =
    downloadedAlertPeriod &&
    `The selected period includes recently downloaded events (${downloadedAlertPeriod[0]} - ${downloadedAlertPeriod[1]}). These events will not be modified.`;

  const isFutureShift = useMemo(
    () =>
      selectedPatchLogEvents.length > 0 &&
      moment
        .tz(
          selectedPatchLogEvents[selectedPatchLogEvents.length - 1].originData.eventTime.timestamp,
          timezones[
            selectedPatchLogEvents[selectedPatchLogEvents.length - 1].originData.eventTime.logDate.timeZone.id
          ] || 'America/Los_Angeles'
        )
        .add(-shiftAmount, patchType)
        .isAfter(),
    [
      selectedPatchLogEvents,
      shiftAmount,
      patchType,
    ]
  );

  const disablePatchActions = !patch || !patchSubscribed || patchLoading || patchIsBeingFetched || !!patch.jobInProcess;

  const disablePatchLogEventsActions =
    !patchLogEvents ||
    !patchLogEvents.length ||
    !patchLogEventsSubscribed ||
    patchLogEventsLoading === undefined ||
    patchLogEventsLoading === true;

  const patchInputsDisabled = disablePatchActions || patchIsShifted || !isAdminOrShifter || !!patch!.shiftFinishedAt;

  const moveButtonDisabled =
    disablePatchActions || !patchIsFinishedOrStopped || !shiftAmount || disablePatchLogEventsActions;

  const finishButtonDisabled =
    disablePatchActions || !patchIsFinishedOrStopped || !patchLogEventsSubscribed || disablePatchLogEventsActions;

  const removeUnidentifiedButtonDisabled =
    numberOfUnidentifiedEvents === 0 ||
    disablePatchActions ||
    patchIsShifted ||
    !patchIsFinishedOrStopped ||
    disablePatchLogEventsActions;

  const deleteUnidentifiedEvents = async () => {
    if (patch) {
      dispatch(ManualPatchSlice.removeUnidentifiedEvents(patch?.id, patch?.version));
    }
  };

  const archiveButtonDisabled =
    testDrivers === undefined ||
    testDrivers.length === 0 ||
    disablePatchActions ||
    patchIsShifted ||
    !patchIsFinishedOrStopped ||
    !!patch!.shiftFinishedAt ||
    disablePatchLogEventsActions;

  const archiveSelectedButtonDisabled =
    ((testDrivers?.length || 0) === 0 && patchDrivers.length < 2) ||
    disablePatchActions ||
    patchIsShifted ||
    !patchIsFinishedOrStopped ||
    !!patch!.shiftFinishedAt ||
    disablePatchLogEventsActions ||
    selectedPatchLogEvents.length === 0 ||
    (patchLogEvents || []).some((event) => event.loading);

  const adjustOdometerButtonDisabled =
    disablePatchActions ||
    patchIsShifted ||
    !patchIsFinishedOrStopped ||
    !!patch!.shiftFinishedAt ||
    disablePatchLogEventsActions ||
    selectedPatchLogEvents.length === 0 ||
    (patchLogEvents || []).some((event) => event.loading);

  const archiveInactivesButtonDisabled =
    testDrivers === undefined ||
    testDrivers.length === 0 ||
    numberOfInactiveEvents === 0 ||
    disablePatchActions ||
    patchIsShifted ||
    !patchIsFinishedOrStopped ||
    !!patch!.shiftFinishedAt ||
    disablePatchLogEventsActions;

  const archiveEvents = (patchLogEvents: PatchLogEventPayload[], includeCoDrivers = false) => {
    if (!patch) {
      return;
    }

    const logIds = patchLogEvents.map((patchLogEvent) => patchLogEvent.logId);
    if (
      (includeCoDrivers && patchDrivers.length < 2 && (testDrivers?.length || 0) === 1) ||
      (!includeCoDrivers && (testDrivers?.length || 0) === 1)
    ) {
      appDispatch(
        PatchLogEventsSlice.reassignLogsToUser({
          manualPatchId: patch.id,
          logIds: logIds,
          userId: testDrivers && testDrivers.length > 0 ? testDrivers[0]._id : patchDrivers[0].driverId,
        })
      );
    } else if (includeCoDrivers || (testDrivers?.length || 0) > 1) {
      if (includeCoDrivers && patchDrivers.length > 1) {
        setCoDriverIncludedInReassign(true);
      }
      setLogsToArchive(logIds);
      setUserSelectionModalVisible(true);
    }
  };

  const confirmArchiveUserSelection = (selectedUser?: string) => {
    setUserSelectionModalVisible(false);
    setCoDriverIncludedInReassign(false);
    if (patch && selectedUser) {
      appDispatch(
        PatchLogEventsSlice.reassignLogsToUser({
          manualPatchId: patch.id,
          logIds: logsToArchive,
          userId: selectedUser,
        })
      );
    }
  };

  const cancelArchiveUserSelection = () => setUserSelectionModalVisible(false);

  const recalculateEngineHoursButtonDisabled =
    disablePatchActions ||
    patchIsShifted ||
    !patchIsFinishedOrStopped ||
    disablePatchLogEventsActions ||
    numberOfInactiveEvents > 0 ||
    numberOfUnidentifiedEvents > 0 ||
    !engineEventPresent ||
    selectedPatchLogEvents.length === 0;

  const generateIntermediatesButtonDisabled =
    disablePatchActions ||
    isGeneratingIntermediateEvents ||
    patchIsShifted ||
    !patchIsFinishedOrStopped ||
    disablePatchLogEventsActions ||
    numberOfInactiveEvents > 0 ||
    numberOfUnidentifiedEvents > 0;

  const recalculateEngineHours = async (patch: ManualPatchPayload) => {
    await appDispatch(ManualPatchSlice.recalculateEngineHours(patch.id));
  };

  const generateIntermediateEvents = async (patch: ManualPatchPayload) => {
    setIsGeneratingIntermediateEvents(true);
    await dispatch(ManualPatchSlice.generateIntermediateEvents(patch.id));
    setIsGeneratingIntermediateEvents(false);
  };

  const recalculateButton = (patch?: ManualPatchPayload) => {
    let tooltipMessage: string | undefined = undefined;
    if (recalculateEngineHoursButtonDisabled && !patchIsShifted && patchIsFinishedOrStopped) {
      tooltipMessage = 'Disabled due to ';
      if (numberOfInactiveEvents > 0) {
        tooltipMessage += 'inactive events';
      } else if (numberOfUnidentifiedEvents > 0) {
        tooltipMessage += 'unidentified events';
      } else if (!engineEventPresent) {
        tooltipMessage += 'absence of engine up and/or shutdown event';
      } else {
        tooltipMessage += 'other reason';
      }
    }

    const confirmationMessage = downloadedAlertPeriod ? (
      <>
        <p>The engine hours values will be changed. Are you sure you want to proceed?</p>
        <p>{skipDownloadedMessage}</p>
      </>
    ) : (
      'The engine hours values will be changed. Are you sure you want to proceed?'
    );

    return patch ? (
      <Col>
        <ConditionalTooltip condition={tooltipMessage !== undefined} title={tooltipMessage}>
          <Button
            type="primary"
            disabled={recalculateEngineHoursButtonDisabled}
            loading={patch?.jobInProcess === 'recalculate-engine-hours'}
            onClick={() => {
              confirm({
                title: 'Confirm recalculate engine hours',
                icon: <ExclamationCircleOutlined />,
                content: confirmationMessage,
                onOk() {
                  // noinspection JSIgnoredPromiseFromCall
                  recalculateEngineHours(patch);
                },
                autoFocusButton: null,
                okText: 'Yes',
                cancelText: 'No',
              });
            }}
          >
            Recalculate engine hours
          </Button>
        </ConditionalTooltip>
      </Col>
    ) : null;
  };

  const reassignButton = () => {
    return (
      <ConditionalTooltip
        condition={!patchIsShifted && patchIsFinishedOrStopped}
        title={`Reassign selected to ${
          patchDrivers.length > 1 && (testDrivers?.length || 0) !== 0
            ? 'Co-Driver or Test Driver'
            : patchDrivers.length > 1
              ? 'Co-Driver'
              : 'Test Driver'
        }`}
      >
        <Button
          type="primary"
          disabled={archiveSelectedButtonDisabled}
          onClick={() => {
            const numberOfSelectedCertificationEvents = selectedPatchLogEvents.filter((event) =>
              event.originData.eventCode.id.startsWith('DR_CERT')
            );
            const reassignDriver =
              patchDrivers.length > 1 && (testDrivers?.length || 0) !== 0
                ? 'Co-Driver or Test Driver'
                : patchDrivers.length > 1
                  ? 'Co-Driver'
                  : 'Test Driver';
            const confirmationMessage =
              numberOfSelectedCertificationEvents.length === 0 ? (
                <>
                  <p>
                    {`Are you sure you want to reassign ${selectedPatchLogEvents.length} selected events to the ${reassignDriver}?`}
                  </p>
                  {skipDownloadedMessage && <p>{skipDownloadedMessage}</p>}
                </>
              ) : (
                <>
                  <p>
                    Are you sure you want to reassign {selectedPatchLogEvents.length} selected events (
                    <strong>including {numberOfSelectedCertificationEvents.length} certifications</strong>) to the{' '}
                    {reassignDriver}?
                  </p>
                  {skipDownloadedMessage && <p>{skipDownloadedMessage}</p>}
                </>
              );
            confirm({
              title: 'Confirm bulk reassign',
              icon: <ExclamationCircleOutlined />,
              content: confirmationMessage,
              onOk() {
                archiveEvents(selectedPatchLogEvents, true);
              },
              autoFocusButton: null,
            });
          }}
          danger
        >
          Reassign {selectedPatchLogEvents.length > 0 ? ` ${selectedPatchLogEvents.length} ` : ''}
          selected
        </Button>
      </ConditionalTooltip>
    );
  };

  const generateIntermediatesButton = (patch?: ManualPatchPayload) => {
    let tooltipMessage: string | undefined = undefined;
    if (generateIntermediatesButtonDisabled && !patchIsShifted && patchIsFinishedOrStopped) {
      tooltipMessage = 'Disabled due to ';
      if (numberOfInactiveEvents > 0) {
        tooltipMessage += 'inactive events';
      } else if (numberOfUnidentifiedEvents > 0) {
        tooltipMessage += 'unidentified events';
      } else {
        tooltipMessage += 'other reason';
      }
    }

    const confirmationMessage = downloadedAlertPeriod ? (
      <>
        <p>Missing intermediate events will be created. Are you sure you want to proceed?</p>
        <p>{skipDownloadedMessage}</p>
      </>
    ) : (
      'Missing intermediate events will be created. Are you sure you want to proceed?'
    );

    return patch ? (
      <Col>
        <ConditionalTooltip condition={tooltipMessage !== undefined} title={tooltipMessage}>
          <Button
            type="primary"
            disabled={generateIntermediatesButtonDisabled}
            loading={isGeneratingIntermediateEvents}
            onClick={() => {
              confirm({
                title: 'Confirm generate intermediates',
                icon: <ExclamationCircleOutlined />,
                content: confirmationMessage,
                onOk() {
                  // noinspection JSIgnoredPromiseFromCall
                  generateIntermediateEvents(patch);
                },
                autoFocusButton: null,
                okText: 'Yes',
                cancelText: 'No',
              });
            }}
          >
            Generate intermediates
          </Button>
        </ConditionalTooltip>
      </Col>
    ) : null;
  };

  const duplicateEventButtonDisabled =
    disablePatchActions || patchIsShifted || !patchIsFinishedOrStopped || disablePatchLogEventsActions;

  const updateEventButtonDisabled =
    disablePatchActions || patchIsShifted || !patchIsFinishedOrStopped || disablePatchLogEventsActions;

  const updateEvent = (event: LogEvent) => {
    setEventUpdateModalVisible(false);
    if (patch) {
      appDispatch(
        PatchLogEventsSlice.updateLogEvent({
          manualPatchId: patch.id,
          id: event._id,
          event,
        })
      );
    }
  };

  const fetchEvents = async (patchId?: string, patchVersion?: number, drivers?: string[]) => {
    if (patch?.driverId && patch?.to && patch?.from) {
      drivers && drivers.length > 0
        ? dispatch(ManualPatchSlice.fetch(patchId || patch?.id, patchVersion || patch?.version, drivers))
        : dispatch(ManualPatchSlice.fetch(patchId || patch?.id, patchVersion || patch?.version));
    }
  };

  const fetchDriverEvents = async (driverId: string) => {
    const driver = patch?.drivers?.find((driver) => driver.driverId === driverId);
    if (patch && driver && driver.from && driver.to) {
      dispatch(ManualPatchSlice.fetch(patch.id, patch.version, [driverId]));
    }
  };

  const updatePatch = async (patchUpdate: UpdateManualPatchPayload) => {
    if (patch) {
      setIsProfileFormVisible(false);
      setIsProfileFormFullScreen(false);
      setProfileFormData(undefined);
      const result = await appDispatch(
        ManualPatchSlice.update(
          {
            ...patchUpdate,
          },
          patch
        )
      );
      if (result.status !== 'ok') {
        message.error(result?.msg || 'Error');
      }
      if (result.status === 'sync_error') {
        await dispatch(ManualPatchSlice.unsubscribe({ id: manualPatchId }));
        await dispatch(ManualPatchSlice.subscribe({ id: manualPatchId }));
      }
      setProfileFormChanged(true);
      return result;
    } else {
      return {
        status: 'error',
      };
    }
  };

  const updatePatchDriver = async (driverUpdate: UpdateManualPatchDriverPayload) => {
    if (patch) {
      setIsProfileFormVisible(false);
      setIsProfileFormFullScreen(false);
      setProfileFormData(undefined);
      const result = await appDispatch(ManualPatchSlice.updateDriver({ ...driverUpdate }));
      if (result.status !== 'ok') {
        message.error(result?.msg || 'Error');
      }
      setProfileFormChanged(true);
      return result;
    } else {
      return {
        status: 'error',
      };
    }
  };

  const removePatchDriver = async (patchDriver: RemoveManualPatchDriverPayload) => {
    if (patch) {
      setIsProfileFormVisible(false);
      setIsProfileFormFullScreen(false);
      setProfileFormData(undefined);
      const result = await appDispatch(ManualPatchSlice.removeDriver({ ...patchDriver }));
      if (result.status !== 'ok') {
        message.error(result?.msg || 'Error');
      }
      setProfileFormChanged(true);
      return result;
    } else {
      return {
        status: 'error',
      };
    }
  };

  const conflictingDots: DotPayload[] = Object.values(dotsById)
    .filter(
      (dot) =>
        !dot.disabled &&
        dot.companyId === patch?.companyId &&
        patchDrivers.map((driver) => driver.driverId).includes(dot.driverId)
    )
    .filter((dot) => {
      const dotFrom = moment(dot.from).subtract(9, 'days').endOf('day');
      const dotTo = moment(dot.from).endOf('day');
      const driver = patchDrivers.find((driver) => driver.driverId === dot.driverId);
      const patchMoments = [
        moment(driver?.from).startOf('day'),
        moment(driver?.to).endOf('day'),
        moment(driver?.from)
          .subtract(shiftAmount, patchType)
          .startOf('day'),
        moment(driver?.to)
          .subtract(shiftAmount, patchType)
          .endOf('day'),
      ];
      const patchMax = moment.max(patchMoments);
      const patchMin = moment.min(patchMoments);

      return (
        patchMin.isBetween(dotFrom, dotTo) ||
        patchMax.isBetween(dotFrom, dotTo) ||
        dotFrom.isBetween(patchMin, patchMax) ||
        dotTo.isBetween(patchMin, patchMax)
      );
    })
    .sort((dot1, dot2) => -moment(dot1.from).diff(moment(dot2.from)))
    .reduce((acc, value) => {
      if (!acc.map((dot) => dot.driverId).includes(value.driverId)) {
        acc.push(value);
      }
      return acc;
    }, [] as DotPayload[]);

  const conflictingSubscriptionLocks: SubscriptionLockPayload[] = Object.values(subscriptionLocksById)
    .filter(
      (subscriptionLock) =>
        !subscriptionLock.disabled &&
        subscriptionLock.companyId === patch?.companyId &&
        (subscriptionLock.driverId
          ? patchDrivers.map((driver) => driver.driverId).includes(subscriptionLock.driverId)
          : true)
    )
    .sort((subscriptionLock1, subscriptionLock2) =>
      !subscriptionLock1.driverId && subscriptionLock2.driverId
        ? -1
        : subscriptionLock1.driverId && !subscriptionLock2.driverId
          ? 1
          : moment(subscriptionLock1.createdAt).diff(moment(subscriptionLock2.createdAt)) < 0
            ? -1
            : 1
    )
    .reduce((acc, value) => {
      if (!value.driverId && acc.filter((subscriptionLock) => !subscriptionLock.driverId).length === 0) {
        acc.push(value);
      }

      if (
        value.driverId &&
        acc.filter((subscriptionLock) => subscriptionLock.driverId && subscriptionLock.driverId === value.driverId)
          .length === 0
      ) {
        acc.push(value);
      }
      return acc;
    }, [] as SubscriptionLockPayload[]);

  const conflictingAudits = Object.values(auditsById)
    .filter(
      (audit) =>
        audit.companyId === patch?.companyId && patchDrivers.map((driver) => driver.driverId).includes(audit.driverId)
    )
    .filter((audit) => {
      const auditFrom = moment(audit.from).subtract(1, 'day').endOf('day');
      const auditTo = moment(audit.to).add(1, 'days').startOf('day');
      const driver = patchDrivers.find((driver) => driver.driverId === audit.driverId);
      const patchMoments = [
        moment(driver?.from).startOf('day'),
        moment(driver?.to).endOf('day'),
        moment(driver?.from)
          .subtract(shiftAmount, patchType)
          .startOf('day'),
        moment(driver?.to)
          .subtract(shiftAmount, patchType)
          .endOf('day'),
      ];
      const patchMax = moment.max(patchMoments);
      const patchMin = moment.min(patchMoments);
      return (
        patchMin.isBetween(auditFrom, auditTo) ||
        patchMax.isBetween(auditFrom, auditTo) ||
        auditFrom.isBetween(patchMin, patchMax) ||
        auditTo.isBetween(patchMin, patchMax)
      );
    })
    .sort((audit1, audit2) => -moment(audit1.from).diff(moment(audit2.from)));

  const [
    showAudit,
    setShowAudit,
  ] = useState<boolean>(false);

  const conflictingAuditKeys = JSON.stringify(conflictingAudits.map((audit) => audit.id));

  useEffect(() => {
    if (conflictingAudits.length > 0) {
      setShowAudit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conflictingAuditKeys]);

  const alertMessages = [
    ...conflictingDots.map((conflictingDot) => [
      `${conflictingDot.driverName} HAD DOT INSPECTION ON ${moment.utc(conflictingDot.from).format('MMM DD, YYYY')}`,
      conflictingDot.message ? conflictingDot.message : 'Contact supervisor for more details',
    ]),
    ...conflictingAudits.map((conflictingAudit) => [
      `${conflictingAudit.driverName} IS PASSING AUDIT INSPECTION FROM ${moment
        .utc(conflictingAudit.from)
        .format('MMM DD, YYYY')} TO ${moment.utc(conflictingAudit.to).format('MMM DD, YYYY')}`,
      conflictingAudit.message ? conflictingAudit.message : 'Contact supervisor for more details',
    ]),
    ...conflictingSubscriptionLocks.map((conflictingSubscriptionLock) => [
      `${
        conflictingSubscriptionLock.driverId ? conflictingSubscriptionLock.driverName : 'COMPANY'
      } IS SUBSCRIPTION BLOCKED`,
      conflictingSubscriptionLock.message ? conflictingSubscriptionLock.message : 'Contact supervisor for more details',
    ]),
  ].filter((alert): alert is [string, string] => !!alert);

  const handleVisibleChange = async (visible: boolean) => {
    if (!visible) {
      setVisible(visible);
      return;
    }
    // Determining condition before show the popconfirm.
    if (!areDownloadedDOTEventsSelected && alertMessages.length === 0 && !isFutureShift) {
      // await shiftData();
      setVisible(false);
    } else {
      setVisible(visible);
    }
  };

  const shiftData = () => {
    if (patch?.id) {
      dispatch(ManualPatchSlice.start(patch?.id, patch?.version, shiftAmount, patchType));
    }
  };

  const createDuplicatePatch = async () => {
    const clientResourceId = v4();
    const { status, msg, data } = await appDispatch(
      create({
        clientResourceId,
        companyId: patch?.companyId,
        driverId: patch?.driverId,
        type: patchType,
        shiftAmount,
        from: patch?.from,
        to: patch?.to,
        drivers: patch?.drivers,
      })
    );
    if (status === 'ok') {
      return { id: data?.id, drivers: data?.drivers, version: 0 };
    } else {
      return { msg };
    }
  };

  const finishButton = (patch?: ManualPatchPayload) =>
    patch ? (
      <Dropdown.Button
        disabled={finishButtonDisabled}
        type="primary"
        placement="topRight"
        icon={<DownOutlined />}
        overlay={
          <Menu
            onClick={(e) => {
              if (e.key === 'finish') {
                appDispatch(ManualPatchSlice.remove(patch?.id, patch?.version));
              }
            }}
          >
            <Menu.Item key="finish">FINISH</Menu.Item>
          </Menu>
        }
        onClick={async () => {
          const result = await appDispatch(ManualPatchSlice.remove(patch?.id, patch?.version));
          if (result.status === 'ok') {
            const patchCreated = await createDuplicatePatch();
            if (patchCreated.id) {
              if (patchCreated.drivers) {
                await fetchEvents(
                  patchCreated.id,
                  patchCreated.version,
                  patchCreated.drivers.map((driver) => driver.driverId)
                );
              } else {
                await fetchEvents(patchCreated.id, patchCreated.version);
              }
              setTimeout(() => {
                setShiftAmount(0);
                history.push(`/activity/${patchCreated.id}`);
                message.success('Patch has been created');
              }, 1000);
            }
          }
        }}
      >
        FINISH AND LOAD NEXT
      </Dropdown.Button>
    ) : null;

  const indeterminateSelected = totalSelected > 0 && totalSelected < sortedEvents.length;
  const allSelected = totalSelected === sortedEvents.length && sortedEvents.length > 0;
  const selectAllDisabled =
    !patch ||
    [
      'shift_started',
      'shift_requested',
    ].includes(patchStatus || '') ||
    sortedEvents.length === 0 ||
    sortedEvents.length === numberOfSuccessful;
  const disabled =
    !myAccount ||
    !!patch?.shiftFinishedAt ||
    ![
      'admin',
      'manager',
      'shifter_hr',
      'shifter_day',
      'shifter_hr_day',
      'unrestricted_shifter_hr_day',
    ].includes(myAccount?.role || '') ||
    patchLogEventsLoading;

  const onSelectAllChange = (state: boolean) => {
    if (patch) {
      appDispatch(
        PatchLogEventsSlice.selectAllLogEvents({
          state,
          manualPatchId: patch.id,
        })
      );
    }
  };
  const onSelectChange = (ids: string[], state: boolean) => {
    if (patch) {
      appDispatch(
        PatchLogEventsSlice.selectLogEvents({
          logIds: ids,
          state,
          manualPatchId: patch.id,
        })
      );
    }
  };

  console.timeEnd('RENDER');
  return patch && myAccount ? (
    <>
      <Drawer placement="right" visible={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        {patchAvailableHours && (
          <AntList<PatchAvailableHourSlot>
            itemLayout="horizontal"
            dataSource={patchAvailableHours}
            renderItem={(item, index) => (
              <AntList.Item>
                <AntList.Item.Meta
                  title={
                    <Button
                      type="text"
                      onClick={() => {
                        if (!list.current) {
                          return;
                        }

                        const index = sortedEvents.findIndex((event) => event.id === item.startEventId);
                        if (index) {
                          setIsDrawerOpen(false);
                          list.current.scrollToRow(index);
                          if (focusTimeout) {
                            clearTimeout(focusTimeout);
                            focusTimeout = undefined;
                          }
                          setFocusedEvent(item.startEventId);
                          focusTimeout = window.setTimeout(() => {
                            setFocusedEvent(undefined);
                          }, 2500);
                        }
                      }}
                    >{`${item.durationInHours}hrs - ${moment(item.date).format('MMM DD, YYYY')}`}</Button>
                  }
                />
              </AntList.Item>
            )}
          />
        )}
      </Drawer>
      <Layout className={'accounts-list-container'}>
        <Layout.Content style={{ display: 'flex', flexDirection: 'column' }}>
          <>
            {(testDrivers && testDrivers.length > 1) || (isCoDriverIncludedInReassign && patchDrivers.length > 1) ? (
              <UserSelectionModal
                isVisible={isUserSelectionModalVisible}
                onConfirm={confirmArchiveUserSelection}
                onCancel={cancelArchiveUserSelection}
                availableDrivers={[
                  ...new Map(
                    [
                      ...(testDrivers || []).map((driver) => ({
                        id: driver._id,
                        fullName: `${driver.firstName} ${driver.lastName}`,
                      })),
                      ...(isCoDriverIncludedInReassign ? patchDrivers : []).map((driver) => ({
                        id: driver.driverId,
                        fullName: driver.driverName,
                      })),
                    ].map((driver) => [
                      driver.id,
                      driver,
                    ])
                  ).values(),
                ]}
              />
            ) : (
              ''
            )}
            <Modal
              className="ant-modal-confirm-warning"
              visible={showAlert}
              cancelButtonProps={{ hidden: true }}
              closable={false}
              onOk={() => setShowAlert(false)}
            >
              <div className="ant-modal-confirm-body-wrapper">
                <div className="ant-modal-confirm-body">
                  <ExclamationCircleOutlined />
                  <span className="ant-modal-confirm-title">Alert</span>
                  <div className="ant-modal-confirm-content">
                    {conflictingAlerts.map((alert) => {
                      return <p key={alert.id}>{alert.message}</p>;
                    })}
                  </div>
                </div>
              </div>
            </Modal>
            <Modal
              className="ant-modal-confirm-warning"
              visible={showAudit}
              cancelButtonProps={{ hidden: true }}
              closable={false}
              onOk={() => setShowAudit(false)}
            >
              <div className="ant-modal-confirm-body-wrapper">
                <div className="ant-modal-confirm-body">
                  <AlertOutlined />
                  <span className="ant-modal-confirm-title">
                    <div>{conflictingAudits?.[0]?.driverName} IS PASSING AUDIT INSPECTION</div>
                    <div>
                      Audit period: {moment.utc(conflictingAudits?.[0]?.from).format('MMM DD, YYYY')}-
                      {moment.utc(conflictingAudits?.[0]?.to).format('MMM DD, YYYY')}
                    </div>
                    <div>
                      Period if moved:{' '}
                      {moment
                        .utc(patch?.from)
                        .subtract(shiftAmount, patchType)
                        .startOf('day')
                        .format('MMM DD, YYYY')}
                      -
                      {moment
                        .utc(patch?.to)
                        .subtract(shiftAmount, patchType)
                        .startOf('day')
                        .format('MMM DD, YYYY')}
                    </div>
                  </span>
                  <div className="ant-modal-confirm-content">
                    <p>
                      {conflictingAudits?.[0]?.message
                        ? conflictingAudits[0].message
                        : 'Contact supervisor for more details'}
                    </p>
                  </div>
                </div>
              </div>
            </Modal>
            <OdometerOffsetForm
              isVisible={isOdometerOffsetModalVisible}
              onConfirm={(offset) => {
                setOdometerOffsetModalVisible(false);
                if (offset !== 0 && patch) {
                  appDispatch(
                    PatchLogEventsSlice.adjustLogEventsOdometer({
                      manualPatchId: patch.id,
                      offset,
                    })
                  );
                }
              }}
              onCancel={() => {
                setOdometerOffsetModalVisible(false);
              }}
              message={(areDownloadedDOTEventsSelected && skipDownloadedMessage) || undefined}
            />
            {eventToUpdate ? (
              <EventUpdateForm
                isVisible={isEventUpdateModalVisible}
                onConfirm={updateEvent}
                onCancel={() => {
                  setEventUpdateModalVisible(false);
                }}
                event={eventToUpdate}
              />
            ) : null}
            <Row style={{ width: '100%' }} justify="space-between">
              <Col sm={24} lg={19} style={{ flex: 1 }}>
                <SelectStep
                  onlyLast8Days={myAccount.role === 'shifter_hr'}
                  disabled={patchInputsDisabled}
                  patch={patch}
                  updatePatch={updatePatch}
                  updatePatchDriver={updatePatchDriver}
                  removePatchDriver={removePatchDriver}
                  fetchEvents={(driverId?: string) => (driverId ? fetchDriverEvents(driverId) : fetchEvents())}
                  onAvailableHrsClick={() => setIsDrawerOpen(true)}
                  onDownloadReportClick={async (driverId: string) => {
                    setReportDriverId(driverId);
                    await appDispatch(ManualPatchSlice.generateReport(patch.id, driverId));
                  }}
                  onProfileForm={(data) => {
                    setProfileFormData(data);
                    setIsProfileFormVisible(true);
                  }}
                />
              </Col>
              <Col lg={5}>
                {driverStatus ? (
                  <Tooltip title="The driver's Hours of Service data is loaded from the ELD portal. The portal usually updates it every 10-15 minutes. If it seems incorrect please click the Refresh HoS button or check it directly in the ELD portal.">
                    <DriverStatusChart
                      onMouseEnter={() => setIsStatusChartHovered(true)}
                      onMouseLeave={() => setIsStatusChartHovered(false)}
                      status={isStatusChartHovered && coDriverStatus !== undefined ? coDriverStatus : driverStatus}
                      isLoading={areDriverStatusesLoading}
                      onReloadData={() => fetchDriverStatuses(true)}
                    />
                  </Tooltip>
                ) : null}
              </Col>
            </Row>
            {hasIncorrectCoDriver && (
              <Alert
                style={{ marginTop: 16 }}
                message={
                  <div>
                    <h3>CO-DRIVER MISMATCH</h3>
                    <b>The patch or the profile form has incorrect co-driver value.</b>
                  </div>
                }
                type="error"
              />
            )}
            {!!downloadedAlert && (
              <Alert
                style={{ marginTop: 16 }}
                message={
                  <div>
                    <h3>{downloadedAlert[0]}</h3>
                    <b>{downloadedAlert[1]}</b>
                  </div>
                }
                type="error"
              />
            )}

            {alertMessages.map(
              (
                [
                  title,
                  description,
                ],
                index
              ) => (
                <Alert
                  key={index}
                  style={{ marginTop: 16 }}
                  message={
                    <div>
                      <h3>{title}</h3>
                      <b>{description}</b>
                    </div>
                  }
                  type="error"
                />
              )
            )}
            {chartEvents && chartEvents?.length > 0 && (
              <Collapse destroyInactivePanel={true} style={{ marginTop: '8px' }}>
                <Panel header="Log chart" key="1">
                  <div className="chart">
                    <DriverLogsChart
                      events={chartEvents}
                      onMount={(setHighlightedEventId) => (setChartEventIdToHighlight = setHighlightedEventId)}
                      onEventHover={(hoveredEvent) => {
                        hoveredEventId.current = hoveredEvent?.id;
                        if (hoveredEvent) {
                          const row = sortedEvents.findIndex((event) => event.id === hoveredEvent.id);
                          list.current?.scrollToRow(row);
                          list.current?.forceUpdateGrid();
                        }
                      }}
                    />
                  </div>
                </Panel>
              </Collapse>
            )}
            {patchLogEvents && patchLogEvents?.length > 0 ? (
              <Tooltip
                placement={'bottom'}
                overlayStyle={{ maxWidth: '100vh' }}
                getPopupContainer={getContainer}
                getTooltipContainer={getContainer}
                title={`${numberOfErrors} errors | ${
                  numberOfSuccessful - numberOfRolledBack
                } succeed | ${numberOfRolledBack} rolled back | ${
                  totalSelected - (numberOfSuccessful + numberOfErrors)
                } left | ignored ${totalNumber - totalSelected}`}
              >
                <Progress
                  format={(percent, successPercent) => `${numberOfSuccessful - numberOfRolledBack}/${totalSelected}`}
                  style={{ marginTop: 8, paddingRight: 30 }}
                  status={numberOfErrors ? 'exception' : 'normal'}
                  success={{
                    percent: Math.round(((numberOfSuccessful - numberOfRolledBack) / totalNumber) * 100),
                  }}
                  percent={Math.round((totalSelected / totalNumber) * 100)}
                />
              </Tooltip>
            ) : null}
            <div
              style={{
                flexGrow: '1',
                display: 'flex',
                flexDirection: 'row',
                minHeight: 0,
                gap: '20px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: isProfileFormVisible ? (isProfileFormFullscreen ? '0%' : '60%') : '100%',
                }}
              >
                <div className="rtl-table-row rtl-table-header">
                  <div className="select">
                    <Checkbox
                      indeterminate={indeterminateSelected}
                      checked={allSelected}
                      disabled={selectAllDisabled || disabled}
                      onChange={() => {
                        onSelectAllChange(indeterminateSelected || !allSelected);
                      }}
                    />
                  </div>
                  <div className={isProfileFormVisible ? 'md' : 'lg'}>TIME ({patchTimezone})</div>
                  <div className="sm">DURATION</div>
                  <div className="xxl">EVENT</div>
                  {!isProfileFormVisible && <div className="md">STATUS</div>}
                  <div className={isProfileFormVisible ? 'lg' : 'xxl'}>LOCATION</div>
                  {!isProfileFormVisible && <div className="md">ORIGIN</div>}
                  <div className="sm">ODOMETER</div>
                  <div className="md">ENGINE HOURS</div>
                  {/*<div>_ID</div>*/}
                  <div className="xxl">NOTES</div>
                  {!isProfileFormVisible && <div className="xs">ID</div>}
                  <div className="lg">DRIVER</div>
                  {!isProfileFormVisible && <div className="sm">STATUS</div>}
                  <div>Actions</div>
                </div>
                <div
                  className="rtl-table"
                  onMouseLeave={() => setChartEventIdToHighlight && setChartEventIdToHighlight(null)}
                >
                  <AutoSizer>
                    {({ width, height }) => (
                      <Spin
                        style={{
                          width,
                          height,
                        }}
                        spinning={
                          [
                            'fetch_started',
                            'fetch_requested',
                          ].includes(patchStatus || '') || !patchLogEventsSubscribed
                        }
                      >
                        <List
                          scrollToIndex={
                            [
                              'shift_requested',
                              'shift_started',
                            ].includes(patchStatus || '') && lastFinishedIndex >= 0
                              ? lastFinishedIndex
                              : undefined
                          }
                          className="rtl-list"
                          ref={list}
                          height={height}
                          overscanRowCount={20}
                          // noRowsRenderer={this._noRowsRenderer}
                          rowCount={(sortedEvents || []).length}
                          rowHeight={40}
                          width={width}
                          rowRenderer={({ index, style }) => {
                            const patchLogEvent = (sortedEvents || [])[index];
                            const logEvent = patchLogEvent.originData;
                            const user = (driversById || {})[logEvent.userId];
                            const patchDriverIndex = (patch?.drivers || []).findIndex(
                              (driver) => driver.driverId === logEvent.userId
                            );
                            const driverColor = driverEventColors[patchDriverIndex === -1 ? 0 : patchDriverIndex];
                            let status;
                            if (patchLogEvent.failedAt) {
                              status = <Tag color={'error'}>ERROR</Tag>;
                            } else if (patchLogEvent.rollbackAt) {
                              status = <Tag color={'warning'}>ROLLED BACK</Tag>;
                            } else if (patchLogEvent.committedAt) {
                              status = <Tag color={'success'}>DONE</Tag>;
                            } else if (!patchLogEvent.selected) {
                              status = <Tag color={'default'}>IGNORED</Tag>;
                            } else {
                              status = <Tag color={'processing'}>PENDING</Tag>;
                            }
                            const selected = patchLogEvent.selected;
                            let patchLogEventErrors = patchLogEvent.error;
                            if (
                              !enableEngineHoursValidation &&
                              patchLogEventErrors?.includes('Engine hours value is incorrect.')
                            ) {
                              patchLogEventErrors = patchLogEventErrors.replace(
                                /Engine hours value is incorrect.\n?/,
                                ''
                              );
                            }

                            let errorsAndWarnings = [
                              patchLogEventErrors,
                              patchLogEvent.warning,
                            ]
                              .filter(Boolean)
                              .join('\n');

                            const hasErrorOrWarning = !!errorsAndWarnings;
                            const speedThreshold =
                              logEvent.eventCode.id === 'DS_D' && !hasErrorOrWarning
                                ? `${
                                    Object.entries(SPEEDING_SPEED_STATE_THRESHOLD).find(([state]) =>
                                      new RegExp(` ${state}$`).test(logEvent.location?.calculatedLocation || '')
                                    )?.[1] || SPEEDING_SPEED_DEFAULT_THRESHOLD
                                  }`
                                : undefined;

                            return (
                              <ConditionalTooltip
                                key={patchLogEvent.id}
                                condition={hasErrorOrWarning || speedThreshold !== undefined}
                                title={hasErrorOrWarning ? errorsAndWarnings : `Speed limit is ${speedThreshold}`}
                                placement="topLeft"
                                // @ts-ignore
                                align={{ targetOffset: ['-20%'] }}
                                overlayStyle={{ whiteSpace: 'pre-line' }}
                              >
                                <div
                                  className={`rtl-table-row ${selected ? 'rtl-table-row-selected' : ''} ${
                                    patchLogEvent.id === hoveredEventId.current ? 'highlighted' : ''
                                  } ${
                                    patchLogEventErrors
                                      ? 'rtl-table-row-has-error'
                                      : patchLogEvent.warning
                                        ? 'rtl-table-row-has-warning'
                                        : ''
                                  } ${patchLogEvent.loading ? 'rtl-table-row-loading' : ''} ${
                                    patchLogEvent.originData.location?.calculatedLocation &&
                                    /\s(?:OR|WA|ID)$/.test(patchLogEvent.originData.location.calculatedLocation)
                                      ? 'rtl-table-row-has-success'
                                      : ''
                                  } ${focusedEvent === patchLogEvent.id ? 'focused' : ''} ${
                                    patchLogEventErrors && patchLogEventErrors.indexOf('Teleport') !== -1
                                      ? 'rtl-table-row-striped'
                                      : ''
                                  }`}
                                  style={{
                                    ...style,
                                    borderBottom: '1px solid #ededed',
                                  }}
                                  onMouseEnter={() =>
                                    setChartEventIdToHighlight && setChartEventIdToHighlight(patchLogEvent.id)
                                  }
                                  data-id={patchLogEvent.id}
                                >
                                  <div className="select">
                                    <Checkbox
                                      checked={selected}
                                      disabled={
                                        selectAllDisabled ||
                                        ['done'].includes(getEventStatus(patchLogEvent)) ||
                                        disabled ||
                                        patchLogEvent.loading
                                      }
                                      // disabled={this.state.disabled}
                                      onChange={(event) => {
                                        if (!patchLogEvent.loading) {
                                          if (event.nativeEvent.shiftKey && lastSelectedAction) {
                                            const notFinishedEvents = sortedEvents.filter((event) => {
                                              return !event.committedAt;
                                            });
                                            const index = notFinishedEvents.findIndex(
                                              ({ id }) => id === lastSelectedAction.id
                                            );
                                            const newIndex = notFinishedEvents.findIndex(
                                              ({ id }) => id === patchLogEvent.id
                                            );
                                            const ids = R.pluck(
                                              'id',
                                              newIndex > index
                                                ? R.slice(index, newIndex + 1, sortedEvents)
                                                : R.slice(newIndex, index + 1, sortedEvents)
                                            );
                                            onSelectChange(ids, !patchLogEvent.selected);
                                          } else {
                                            onSelectChange([patchLogEvent.id], !patchLogEvent.selected);
                                          }
                                          setLastSelectedAction({
                                            id: patchLogEvent.id,
                                            state: !patchLogEvent.selected,
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className="lg">
                                    {moment
                                      .tz(
                                        logEvent.eventTime.timestamp,
                                        timezones[logEvent.eventTime.logDate.timeZone.id] || 'America/Los_Angeles'
                                      )
                                      .format('MMM DD, hh:mm:ss a')}
                                  </div>
                                  <div className="sm">
                                    {patchLogEvent.duration !== undefined
                                      ? convertMsToTime(patchLogEvent.duration)
                                      : ''}
                                  </div>
                                  <div className="xxl">{getEventLabel(logEvent)}</div>
                                  {!isProfileFormVisible && (
                                    <div className="md">
                                      {statusLabels[logEvent.recordStatus?.id] || 'ACTIVE (EMPTY)'}
                                    </div>
                                  )}
                                  <div className="xxl">
                                    <CopyToClipboard
                                      tooltip="Copy coordinates"
                                      text={
                                        logEvent.location?.lat && logEvent.location?.lon
                                          ? `${logEvent.location.lat}, ${logEvent.location.lon}`
                                          : undefined
                                      }
                                    >
                                      {logEvent.location?.calculatedLocation}
                                    </CopyToClipboard>
                                  </div>
                                  {!isProfileFormVisible && (
                                    <div className="md">
                                      {originLabels[logEvent.recordOrigin?.id] || logEvent.recordOrigin?.id}
                                    </div>
                                  )}
                                  <div className="sm">
                                    {logEvent.totalVehicleMiles &&
                                      logEvent.totalVehicleMiles + (patchLogEvent?.odometerOffset || 0)}
                                  </div>
                                  <div className="md">{logEvent.totalEngineHours}</div>
                                  {/*<div>{logEvent._id}</div>*/}
                                  <div className="xxl">{logEvent.eventComment}</div>
                                  {!isProfileFormVisible && (
                                    <div className="xs">{logEvent.seqId && parseInt(logEvent.seqId, 16)}</div>
                                  )}
                                  <div
                                    className="lg"
                                    style={{
                                      color: driverColor,
                                    }}
                                  >
                                    {user?.firstName} {user?.lastName}
                                  </div>
                                  {!isProfileFormVisible && <div className="sm">{status}</div>}
                                  <div>
                                    <Space>
                                      <Tooltip
                                        title={
                                          [
                                            'DIAG_LOGGED',
                                            'DIAG_CLEARED',
                                          ].includes(logEvent.eventCode.id)
                                            ? 'Duplicating Data Diagnostic events is not allowed'
                                            : !!logEvent.i
                                              ? 'Duplicating downloaded events is not allowed'
                                              : 'Duplicate the event'
                                        }
                                      >
                                        <Button
                                          type="primary"
                                          shape="circle"
                                          icon={<CopyOutlined />}
                                          size="small"
                                          disabled={
                                            duplicateEventButtonDisabled ||
                                            patchLogEvent.loading ||
                                            [
                                              'DIAG_LOGGED',
                                              'DIAG_CLEARED',
                                            ].includes(logEvent.eventCode.id) ||
                                            !!logEvent.i
                                          }
                                          onClick={() =>
                                            appDispatch(
                                              PatchLogEventsSlice.duplicateLogEvent({
                                                manualPatchId,
                                                logId: patchLogEvent.id,
                                              })
                                            )
                                          }
                                        />
                                      </Tooltip>
                                      <Tooltip
                                        title={
                                          !!logEvent.i
                                            ? 'Attempt to edit DOWNLOADED event. WARNING! Usually downloaded events cannot be edited for 9 days!'
                                            : 'Edit the event'
                                        }
                                      >
                                        <Button
                                          type="primary"
                                          danger
                                          style={
                                            !!logEvent.i ? { backgroundColor: '#fce100', borderColor: '#fce100' } : {}
                                          }
                                          shape="circle"
                                          icon={<EditOutlined style={!!logEvent.i ? { color: '#ff4d4f' } : {}} />}
                                          size="small"
                                          disabled={updateEventButtonDisabled || patchLogEvent.loading}
                                          onClick={() => {
                                            setEventToUpdate({
                                              ...patchLogEvent.originData,
                                              odometerOffset: patchLogEvent.odometerOffset,
                                            });
                                            setEventUpdateModalVisible(true);
                                          }}
                                        />
                                      </Tooltip>
                                      <Tooltip
                                        title={
                                          testDrivers && testDrivers.length === 0
                                            ? 'No Test Driver found'
                                            : 'Assign to Test Driver'
                                        }
                                      >
                                        <Popconfirm
                                          title="Reassign the Certification status to the test driver, are you sure?"
                                          okText="Yes"
                                          cancelText="No"
                                          disabled={!logEvent.eventCode.id.startsWith('DR_CERT_')}
                                          onConfirm={() => {
                                            if (isDownloadedDOTEvent(logEvent)) {
                                              confirm({
                                                title: 'Confirm reassign',
                                                icon: <ExclamationCircleOutlined />,
                                                content:
                                                  'Attempt to reassign DOWNLOADED event. WARNING! Usually downloaded events cannot be edited for 9 days!',
                                                onOk() {
                                                  // noinspection JSIgnoredPromiseFromCall
                                                  archiveEvents([patchLogEvent]);
                                                },
                                                autoFocusButton: null,
                                                okText: 'Yes',
                                                cancelText: 'No',
                                              });
                                            } else {
                                              archiveEvents([patchLogEvent]);
                                            }
                                          }}
                                        >
                                          <Button
                                            type="primary"
                                            danger
                                            shape="circle"
                                            style={
                                              isDownloadedDOTEvent(logEvent)
                                                ? { backgroundColor: '#fce100', borderColor: '#fce100' }
                                                : {}
                                            }
                                            icon={
                                              <UserSwitchOutlined
                                                style={isDownloadedDOTEvent(logEvent) ? { color: '#ff4d4f' } : {}}
                                              />
                                            }
                                            size="small"
                                            disabled={archiveButtonDisabled || patchLogEvent.loading}
                                            onClick={(event) => {
                                              if (logEvent.eventCode.id.startsWith('DR_CERT_')) {
                                                return;
                                              }

                                              if (isDownloadedDOTEvent(logEvent)) {
                                                confirm({
                                                  title: 'Confirm reassign',
                                                  icon: <ExclamationCircleOutlined />,
                                                  content:
                                                    'Attempt to reassign DOWNLOADED event. WARNING! Usually downloaded events cannot be edited for 9 days!',
                                                  onOk() {
                                                    // noinspection JSIgnoredPromiseFromCall
                                                    archiveEvents([patchLogEvent]);
                                                  },
                                                  autoFocusButton: null,
                                                  okText: 'Yes',
                                                  cancelText: 'No',
                                                });
                                              } else {
                                                archiveEvents([patchLogEvent]);
                                              }
                                            }}
                                          />
                                        </Popconfirm>
                                      </Tooltip>
                                    </Space>
                                  </div>
                                </div>
                              </ConditionalTooltip>
                            );
                          }}
                        />
                      </Spin>
                    )}
                  </AutoSizer>
                </div>
              </div>
              {isProfileFormVisible && profileFormData && (
                <Card
                  className={[
                    'profile-form-card',
                    isProfileFormFullscreen && 'full-screen',
                  ]
                    .filter(Boolean)
                    .join(' ')}
                  title={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h4>Profile Form</h4>
                      <Button
                        size="small"
                        icon={isProfileFormFullscreen ? <ShrinkOutlined /> : <ArrowsAltOutlined />}
                        onClick={() => setIsProfileFormFullScreen((fullScreen) => !fullScreen)}
                      />
                    </div>
                  }
                  size="small"
                >
                  <ProfileForm
                    onClose={() => {
                      setIsProfileFormVisible(false);
                      setIsProfileFormFullScreen(false);
                    }}
                    onCoDriverChanged={() => {
                      setProfileFormChanged(true);
                    }}
                    driverId={profileFormData.driverId}
                    companyId={profileFormData.companyId}
                    from={profileFormData.from}
                    to={profileFormData.to}
                    actionsDisabled={disablePatchActions}
                    onCertify={async (driverId, signature) => {
                      const { msg } = await appDispatch(
                        ManualPatchSlice.certifyManualPatch(patch.id, driverId, signature)
                      );
                      if (msg) {
                        Modal.warning({
                          title: 'Certification failed',
                          content: <span dangerouslySetInnerHTML={{ __html: msg }} />,
                        });
                      } else {
                        Modal.info({
                          title: 'Certification successful',
                          content: (
                            <span>
                              Please check that all the certifications are correct.
                              <br />
                              <br />
                              Note, we never certify the days before the patch start date and the last day of the patch.
                            </span>
                          ),
                        });
                      }
                    }}
                  />
                </Card>
              )}
            </div>
            <Row style={{ marginTop: '1rem' }} justify="end" gutter={16} align="middle">
              <Col style={{ marginRight: 'auto' }}>
                <Dropdown
                  placement="topLeft"
                  disabled={disablePatchActions}
                  overlay={
                    <Menu
                      onClick={async (e) => {
                        if (e.key === 'duplicate') {
                          const patchCreated = await createDuplicatePatch();
                          if (patchCreated.id) {
                            setShiftAmount(0);
                            history.push(`/activity/${patchCreated.id}`);
                            message.success('Patch has been created');
                          } else {
                            message.error(patchCreated.msg || 'Error');
                          }
                        } else if (e.key === 'adjust-odometer') {
                          setOdometerOffsetModalVisible(true);
                        } else if (e.key === 'select-certifications') {
                          if (patch) {
                            await appDispatch(
                              PatchLogEventsSlice.selectAllLogEvents({ manualPatchId: patch.id, state: false })
                            );
                            if (onlyCertificationEventsSelected) {
                              await appDispatch(
                                PatchLogEventsSlice.selectLogEvents({
                                  manualPatchId: patch.id,
                                  logIds: (patchLogEvents || [])
                                    .filter(
                                      (patchLogEvent) => !patchLogEvent.originData.eventCode.id.startsWith('DR_CERT_')
                                    )
                                    .map((patchLogEvent) => patchLogEvent.id),
                                  state: true,
                                })
                              );
                            } else {
                              await appDispatch(
                                PatchLogEventsSlice.selectLogEvents({
                                  manualPatchId: patch.id,
                                  logIds: certificationEvents.map((patchLogEvent) => patchLogEvent.id),
                                  state: true,
                                })
                              );
                            }
                          }
                        }
                      }}
                    >
                      <Menu.Item key="duplicate">NEXT LOG</Menu.Item>
                      <Menu.Item key="adjust-odometer" disabled={adjustOdometerButtonDisabled}>
                        ADJUST ODOMETER
                      </Menu.Item>
                      <Menu.Item key="select-certifications" disabled={certificationEvents.length === 0}>
                        {onlyCertificationEventsSelected ? 'UNSELECT' : 'SELECT'} CERTIFICATIONS (
                        <strong>{certificationEvents.length}</strong>)
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button>{disablePatchActions ? <LoadingOutlined /> : <EllipsisOutlined />}</Button>
                </Dropdown>
              </Col>
              {patch.shiftFinishedAt &&
              ([
                'admin',
                'manager',
              ].includes(myAccount.role || '') ||
                ([
                  'shifter_hr',
                  'shifter_day',
                  'shifter_hr_day',
                  'unrestricted_shifter_hr_day',
                ].includes(myAccount?.role || '') &&
                  patch.ownerId === myAccount?.id)) ? (
                <>
                  <Col>{`Moved on ${patch.shiftAmount} ${patch.type} `.toUpperCase()}</Col>
                  {patchStatus === 'shift_rollback_started' ? (
                    <Col>
                      <Button
                        color="primary"
                        onClick={() => {
                          appDispatch(ManualPatchSlice.stopRollback(patch?.id, patch?.version));
                        }}
                      >
                        STOP ROLLBACK
                      </Button>
                    </Col>
                  ) : (
                    <>
                      {numberOfRolledBack > 0 ? (
                        <Col>
                          <Button
                            color="primary"
                            onClick={() => {
                              appDispatch(ManualPatchSlice.startRollback(patch?.id, patch?.version, true));
                            }}
                          >
                            RESTART ROLLBACK
                          </Button>
                        </Col>
                      ) : null}

                      {numberOfRolledBack !== numberOfSuccessful ? (
                        <Col>
                          <Button
                            onClick={() => {
                              appDispatch(ManualPatchSlice.startRollback(patch?.id, patch?.version, false));
                            }}
                            color="primary"
                            type="primary"
                          >
                            {numberOfRolledBack > 0 ? 'CONTINUE ROLLBACK' : 'ROLLBACK'}
                          </Button>
                        </Col>
                      ) : null}
                    </>
                  )}
                </>
              ) : null}
              {!patch.shiftFinishedAt &&
              [
                'admin',
                'manager',
                'shifter_hr',
                'shifter_day',
                'shifter_hr_day',
                'unrestricted_shifter_hr_day',
              ].includes(myAccount.role || '') ? (
                <>
                  <Col>{reassignButton()}</Col>
                  <Col>
                    <ConditionalTooltip
                      condition={!patchIsShifted && patchIsFinishedOrStopped}
                      title="Reassign inactive to Test Driver"
                    >
                      <Button
                        type="primary"
                        disabled={archiveInactivesButtonDisabled}
                        onClick={() => {
                          archiveEvents(
                            sortedEvents.filter((event) => event.originData.recordStatus?.id.startsWith('INACTIVE_'))
                          );
                        }}
                      >
                        Reassign {numberOfInactiveEvents > 0 ? ` ${numberOfInactiveEvents} ` : ''} inactive
                      </Button>
                    </ConditionalTooltip>
                  </Col>
                  <Col
                    style={{
                      color: numberOfUnidentifiedEvents > 0 ? 'red' : 'initial',
                    }}
                  >
                    <ConditionalTooltip
                      condition={!patchIsShifted && patchIsFinishedOrStopped}
                      title="Delete unidentified events"
                    >
                      <Button
                        type="primary"
                        disabled={removeUnidentifiedButtonDisabled}
                        onClick={deleteUnidentifiedEvents}
                      >
                        Delete {numberOfUnidentifiedEvents > 0 ? ` ${numberOfUnidentifiedEvents} ` : ''} unidentified
                      </Button>
                    </ConditionalTooltip>
                  </Col>
                  {generateIntermediatesButton(patch)}
                  {recalculateButton(patch)}
                  <Col>
                    <Button
                      type="primary"
                      disabled={!hasErrorOrWarning}
                      onClick={() => {
                        list.current?.scrollToRow(eventsWithErrorOrWarnings[currentEventWithErrorIdx--]);
                        if (currentEventWithErrorIdx < 0) {
                          currentEventWithErrorIdx = eventsWithErrorOrWarnings.length - 1;
                        }
                      }}
                    >
                      Scroll from last error
                    </Button>
                  </Col>
                  <Col>
                    <Switch
                      title="Check engine hours values"
                      checkedChildren="ON"
                      unCheckedChildren="OFF"
                      checked={enableEngineHoursValidation}
                      onChange={onSwitchEngineHoursValidation}
                    />
                  </Col>
                  <Col>
                    <Tooltip
                      mouseEnterDelay={1}
                      getPopupContainer={getContainer}
                      getTooltipContainer={getContainer}
                      title={`Specify shift back in ${patchType}`}
                    >
                      <InputNumber
                        disabled={patchInputsDisabled}
                        value={shiftAmount}
                        precision={0}
                        onChange={(val) => val !== null && setShiftAmount(val)}
                        placeholder={`${patchType === 'hours' ? 'HH' : 'DD'}`}
                      />
                    </Tooltip>
                  </Col>
                  <Col>
                    <Radio.Group
                      defaultValue={manualPatchType}
                      disabled={patchInputsDisabled}
                      onChange={(e) => {
                        setPatchType(e.target.value);
                      }}
                      buttonStyle="solid"
                    >
                      <Radio.Button value="days">Days</Radio.Button>
                      {[
                        'unrestricted_shifter_hr_day',
                        'shifter_hr_day',
                        'manager',
                        'admin',
                      ].includes(myAccount.role || '') ? (
                        <Radio.Button value="hours">Hours</Radio.Button>
                      ) : null}
                      <Radio.Button value="minutes">Minutes</Radio.Button>
                    </Radio.Group>
                  </Col>
                  <Col>
                    <Button
                      disabled={
                        ![
                          'shift_started',
                          'shift_requested',
                        ].includes(patchStatus || '')
                      }
                      color="primary"
                      onClick={() => {
                        appDispatch(ManualPatchSlice.stop(patch?.id, patch?.version));
                      }}
                    >
                      STOP
                    </Button>
                  </Col>
                  <Col>
                    <Popconfirm
                      getPopupContainer={() => document.body}
                      getTooltipContainer={() => document.body}
                      placement="top"
                      visible={visible}
                      onVisibleChange={handleVisibleChange}
                      title={
                        (areDownloadedDOTEventsSelected && skipDownloadedMessage) ||
                        alertMessages[0]?.[0] ||
                        (isFutureShift && 'You are going to shift events to future date')
                      }
                      onConfirm={shiftData}
                      okText="Continue"
                      disabled={moveButtonDisabled}
                      cancelText="Cancel"
                    >
                      <Button
                        onClick={
                          !areDownloadedDOTEventsSelected && alertMessages.length === 0 && !isFutureShift
                            ? shiftData
                            : undefined
                        }
                        disabled={moveButtonDisabled || numberOfSuccessful === totalSelected}
                        color="primary"
                        type="primary"
                      >
                        Move on ({shiftAmount}{' '}
                        {patchType === 'minutes'
                          ? shiftAmount > 1
                            ? 'minutes'
                            : 'minute'
                          : patchType === 'hours'
                            ? shiftAmount > 1
                              ? 'hours'
                              : 'hour'
                            : shiftAmount > 1
                              ? 'days'
                              : 'day'}
                        )
                      </Button>
                    </Popconfirm>
                  </Col>
                  {patch.shiftStoppedAt && <Col>{finishButton(patch)}</Col>}
                </>
              ) : null}
            </Row>
          </>
        </Layout.Content>
      </Layout>
    </>
  ) : null;
};
export default ManualPatchDetails;
