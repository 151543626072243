import { combineReducers } from '@reduxjs/toolkit';
import account from 'slices/account';
import accountManualPatches from 'slices/accountManualPatches';
import accounts from 'slices/accounts';
import alerts from 'slices/alerts';
import audits from 'slices/audits';
import companies from 'slices/companies';
import connection from 'slices/connection';
import dots from 'slices/dots';
import drivers from 'slices/drivers';
import errors from 'slices/errors';
import manualPatch from 'slices/manualPatch';
import manualPatches from 'slices/manualPatches';
import myAccount from 'slices/myAccount';
import patchLogEvents from 'slices/patchLogEvents';
import subscriptionLocks from 'slices/subscriptionLocks';
import vehicles from 'slices/vehicles';
import { StateType } from 'typesafe-actions';

export const reducers = combineReducers({
  audits,
  alerts,
  dots,
  myAccount,
  account,
  drivers,
  vehicles,
  accounts,
  companies,
  connection,
  manualPatches,
  patchLogEvents,
  manualPatch,
  accountManualPatches,
  subscriptionLocks,
  errors,
});

export type RootState = StateType<typeof reducers>;
