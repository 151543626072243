import { useMemo, useRef, type DependencyList } from 'react';
import type { DepsAreEqual } from './types';

export function useCustomCompareMemoize<TDependencyList extends DependencyList>(
  deps: TDependencyList,
  depsAreEqual: DepsAreEqual<TDependencyList>
): TDependencyList {
  const ref = useRef<TDependencyList | undefined>(undefined);
  const prevRef = useRef<TDependencyList | undefined>(ref.current);

  if (prevRef.current === undefined || !depsAreEqual(prevRef.current, deps)) {
    ref.current = deps;
  }

  prevRef.current = deps;

  return ref.current as TDependencyList;
}

function useCustomCompareMemo<T, TDependencyList extends DependencyList>(
  factory: () => T,
  deps: TDependencyList,
  depsAreEqual: DepsAreEqual<TDependencyList>
): T {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(factory, useCustomCompareMemoize(deps, depsAreEqual));
}

export default useCustomCompareMemo;
